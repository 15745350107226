import { AgeGroupOptions } from "@components/ContactRequest/OptionData/ContactRequestPreferenceOptions";

import createApiModule from "../create-api-module";

/**
 * Obtain the currently authenticated users profile
 */

const { reducer, actions } = createApiModule("profile", () => `/patients/profile`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface ProfileData {
    sfUserId: string;
    avatar: string;
    username: string;
    gender: string;
    disease: string;
    role: string;
    ageGroup: AgeGroupOptions;
}

export interface Profile {
    data?: ProfileData;
    loading?: boolean;
    error?: {
        awsRequestId: string;
        message: string;
        errorCode: string;
    };
    params?: object;
}
