import { QuestionData } from "templates/services/ask-the-experts/types/QuestionProps";

import createApiModule from "../create-api-module";

export interface AskTheExpertsQuestions {
    data: QuestionData[];
    error: {
        message: string;
        errorCode: string;
        awsRequestId: string;
    };
    loading: boolean;
}

/**
 * Gets the list of questions with some filters.
 * Using this redux to avoid duplicate call to this endpoint when loading
 * all questions to get the specialty list.
 *
 * This is due to useEffect being executed twice on page load until we can
 * figure out the cause.
 *
 */
const { reducer, actions } = createApiModule("askTheExpertsQuestions", ({ specialty, status }) => {
    const params = new URLSearchParams();
    if (specialty) params.append("specialty", specialty as unknown as string);
    if (status) params.append("status", status as unknown as string);

    const query = status || specialty ? "?" + params.toString() : "";

    return `/ask-the-experts/questions/${query}`;
});

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
