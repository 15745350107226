import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { CMSMainNavigation } from "@jwm/types/CMSMainNavigation";
import { graphql, useStaticQuery } from "gatsby";

export const useMainNavigation = (): CMSMainNavigation => {
    const locale = useLocale();

    const { allContentstackMainNavigation }: { allContentstackMainNavigation: { nodes: [CMSMainNavigation] } } =
        useStaticQuery(graphql`
            {
                allContentstackMainNavigation {
                    nodes {
                        ...mainNavigationFields
                    }
                }
            }
        `);
    const mainNavigation = allContentstackMainNavigation?.nodes
        .filter((item: CMSMainNavigation) => {
            return item?.publish_details?.locale == locale ? true : false;
        })
        .pop();

    return mainNavigation;
};

export default useMainNavigation;
