import "./i18n";

import { loadTranslations } from "@utils/translations";
import { graphql, useStaticQuery } from "gatsby";
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TranslateContext = createContext(null);

interface PropTypes {
    language: string;
    children: JSX.Element;
}

export const TranslationProvider = ({ language, children }: PropTypes): JSX.Element => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.documentElement.dir = language ? i18n.dir() : "ltr";
    }, [language]);

    const { translations } = useStaticQuery(
        graphql`
            query {
                translations: allContentstackLiteral {
                    nodes {
                        title
                        translation
                        context
                        locale
                    }
                }
            }
        `,
    );

    loadTranslations(i18n, translations.nodes);

    if (language !== i18n.language && language !== "") {
        i18n.changeLanguage(language);
    }

    return <TranslateContext.Provider value={t}>{children}</TranslateContext.Provider>;
};

export const withTranslate =
    <T extends object>(Component: React.ReactNode) =>
    (props: Partial<T>): JSX.Element =>
        (
            // eslint-disable-next-line
            <TranslateContext.Consumer>
                {(translate) => <Component {...props} t={translate} />}
            </TranslateContext.Consumer>
        );

export const TranslationProviderMock = ({ language, children }: PropTypes): JSX.Element => {
    return (
        <TranslateContext.Provider value={(key: string): string => `${language}: ${key}`}>
            {children}
        </TranslateContext.Provider>
    );
};

export default { withTranslate, TranslationProvider };
