import JWMLink from "@components/JWMLink/JWMLink";
import PlatformLogo from "@components/PlatformLogo/PlatformLogo";
import { Icon, jnjHome } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import Html from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import {
    InterestingLinks,
    MenuItem,
    SubMenu,
} from "@jmc/solid-design-system/src/components/molecules/MegaMenu/MegaMenu";
import { Image } from "@jmc/solid-design-system/src/components/organisms/Image/Image";
import cleanCSSIdentifier from "@jmc/utils/utils/clean-css-identifier";
import {
    CMSInterestingLink,
    CMSMainNavigation,
    CMSMainNavigationLink,
    CMSMainNavigationMenu,
    Links,
} from "@jwm/types/CMSMainNavigation";
import {
    CMSExternalPageLink,
    CMSInternalPageLink,
    CMSMinisite,
    CMSServicePageLink,
    CMSSiteMenuItem,
    CMSSubMenu,
} from "@jwm/types/CMSMinisite";
import { default as getServicePageUrl } from "@utils/getServicePageUrl";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import yn from "yn";

export const getItemId = (label: string, labelPrefix = ""): string => {
    const prefixName = labelPrefix ? `${labelPrefix}.` : "";
    return `${prefixName}${cleanCSSIdentifier(label)}`;
};

export const buildNavigationLink = (
    jnjFullBranded: boolean,
    link: CMSMainNavigationLink,
    index = 0,
    labelPrefix = "",
    showId = true,
    labelStyle = false,
): JSX.Element => {
    const url = link?.external_link ? link?.external_link : link?.internal_page?.[0]?.url;
    const url_prefix = link?.internal_page?.[0]?.site_reference?.[0]?.url_prefix;
    const external = link?.external_link ? true : false;
    const id = getItemId(link?.label, labelPrefix);

    if (url) {
        return (
            <JWMLink
                key={index}
                url={url}
                url_prefix={url_prefix}
                anchor_id={link?.anchor_id}
                external={external}
                ariaLabel={null}
                id={showId ? id : null}
                data-linkname={!showId ? id : null}
                fullWidth={!(labelStyle && jnjFullBranded)}
            >
                {labelStyle && jnjFullBranded ? (
                    <Typography variant="label-03-link" link>
                        {link?.label}
                    </Typography>
                ) : (
                    <div>{link?.label}</div>
                )}
            </JWMLink>
        );
    } else {
        return (
            <button tabIndex={0} id={showId ? id : null} data-linkname={!showId ? id : null}>
                <div>{link?.label}</div>
            </button>
        );
    }
};

export const buildNavigationMenu = (jnjFullBranded: boolean, navigation: CMSMainNavigationMenu[]): JSX.Element[] => {
    const processSubmenu = (links: Links[]): JSX.Element | JSX.Element[] => {
        return links.map((item, key) => {
            if (item?.menu_link) {
                return (
                    <MenuItem key={key}>
                        {buildNavigationLink(jnjFullBranded, item?.menu_link, key, `MenuItem.${key}`)}
                    </MenuItem>
                );
            } else {
                return (
                    <SubMenu
                        key={key}
                        title={item?.submenu?.label}
                        link={buildNavigationLink(jnjFullBranded, item?.submenu)}
                    >
                        {processSubmenu(item?.submenu?.links)}
                    </SubMenu>
                );
            }
        });
    };

    const menu = navigation?.map((item, key) => {
        const link = buildNavigationLink(jnjFullBranded, item?.category, key, `SubMenu.Item.${key}`);
        const mobileLink = buildNavigationLink(jnjFullBranded, item?.category, key, `Mobile.SubMenu.Item.${key}`);

        if (item?.category?.links.length > 0) {
            return (
                <SubMenu key={key} title={item?.category?.label} link={link} mobileLink={mobileLink}>
                    {processSubmenu(item?.category?.links)}
                </SubMenu>
            );
        } else {
            return <MenuItem key={key}>{link}</MenuItem>;
        }
    });

    return menu;
};

export const buildSiteMenu = (siteMenu: CMSSiteMenuItem[]): JSX.Element[] => {
    const InternalLink = (props: { link: CMSInternalPageLink; index: number }): JSX.Element => {
        const { link, index } = props;
        return (
            <JWMLink
                url={link?.page_reference?.[0]?.url}
                url_prefix={link?.page_reference?.[0]?.site_reference?.[0]?.url_prefix}
                anchor_id={link?.anchor_id}
                ariaLabel={null}
                id={getItemId(link?.menu_label, `MenuItem.${index}`)}
            >
                <Html>{link?.menu_label}</Html>
            </JWMLink>
        );
    };

    const ExternalLink = (props: { link: CMSExternalPageLink; index: number }): JSX.Element => {
        const { link, index } = props;
        return (
            <JWMLink
                external={true}
                url={link?.link?.href}
                ariaLabel={null}
                id={getItemId(link?.link?.title, `MenuItem.${index}`)}
            >
                <Html>{link?.link?.title}</Html>
            </JWMLink>
        );
    };

    const ServiceLink = (props: { link: CMSServicePageLink; index: number }): JSX.Element => {
        const { link, index } = props;
        const serviceUrl = link?.service_page
            ? getServicePageUrl(link?.service_page, link?.specialty?.[0]?.title)
            : null;

        return (
            <JWMLink
                url={serviceUrl}
                external={false}
                ariaLabel={null}
                id={getItemId(link?.menu_label, `MenuItem.${index}`)}
            >
                <Html>{link?.menu_label}</Html>
            </JWMLink>
        );
    };

    const buildSubMenu = (submenu: CMSSubMenu, key: number): JSX.Element => {
        const children = submenu.menu_item.map((item, key) => {
            if (item?.submenu) {
                return buildSubMenu(item.submenu, key);
            } else if (item?.internal_page_link) {
                return (
                    <MenuItem key={key}>
                        <InternalLink link={item.internal_page_link} index={key} />
                    </MenuItem>
                );
            } else if (item?.service_link) {
                return (
                    <MenuItem key={key}>
                        <ServiceLink link={item.service_link} index={key} />
                    </MenuItem>
                );
            } else {
                return (
                    <MenuItem key={key}>
                        <ExternalLink link={item.external_link} index={key} />
                    </MenuItem>
                );
            }
        });
        return (
            <SubMenu key={key} title={submenu.menu_label}>
                {children}
            </SubMenu>
        );
    };

    if (siteMenu?.length > 0) {
        const menu = siteMenu.map((item, key) => {
            if (item.submenu) {
                return buildSubMenu(item.submenu, key);
            } else if (item.internal_page_link) {
                return (
                    <MenuItem key={key}>
                        <InternalLink link={item.internal_page_link} index={key} />
                    </MenuItem>
                );
            } else {
                return (
                    <MenuItem key={key}>
                        <ExternalLink link={item.external_link} index={key} />
                    </MenuItem>
                );
            }
        });
        return menu;
    } else {
        return null;
    }
};

export const buildSiteLogo = (site: CMSMinisite, title: string): JSX.Element => {
    const siteLogo =
        (site?.branding?.logo &&
            (site?.branding?.logo?.gatsbyImageData ? (
                <GatsbyImage
                    image={site?.branding?.logo?.gatsbyImageData}
                    imgStyle={{ objectFit: "contain" }}
                    alt={title || ""}
                    loading="eager"
                />
            ) : (
                <Image url={site?.branding?.logo?.url} alt={title} fit="contain" enableLightBox={false} />
            ))) ||
        null;

    const url = site?.startpage && site?.startpage.length ? site?.startpage[0].url : "";
    const url_prefix = site?.url_prefix || "";

    if (url && siteLogo) {
        return (
            <JWMLink url={url} url_prefix={url_prefix} external={false}>
                {siteLogo}
            </JWMLink>
        );
    } else {
        return siteLogo;
    }
};

const isMainSite = (site: CMSMinisite): boolean => {
    return !site || site?.url_prefix === "/";
};

export const buildLogo = (configuration: CMSMainNavigation, site: CMSMinisite, title: string): JSX.Element => {
    const platformDisabled = yn(process.env.GATSBY_DISABLE_PLATFORM, { default: false });
    const siteLogo = buildSiteLogo(site as CMSMinisite, title);

    if (siteLogo) {
        return siteLogo;
    } else if (isMainSite(site) && !platformDisabled && !site?.standalone) {
        return <PlatformLogo position="header" custom={configuration?.logo_variant} />;
    } else {
        return null;
    }
};

export const buildInterestingLinks = (
    jnjFullBranded: boolean,
    title: string,
    links: CMSInterestingLink[],
): JSX.Element => {
    const children = links.map((item, index) => {
        return buildNavigationLink(jnjFullBranded, item?.link, index, "interesting_links", false);
    });

    return <InterestingLinks title={title}>{children}</InterestingLinks>;
};

export const buildTopBar = (
    site: CMSMinisite,
    title: string,
    topBarName: string,
    jnjFullBranded = false,
): JSX.Element => {
    const platformDisabled = yn(process.env.GATSBY_DISABLE_PLATFORM, { default: false });

    if (process.env.GATSBY_HOME_URL !== "false" && !platformDisabled) {
        return !isMainSite(site) && !site?.standalone ? (
            <JWMLink
                name={topBarName}
                placement={`page:${title || ""} | navigation`}
                fullWidth={false}
                url={process.env.GATSBY_HOME_URL || "/"}
                ariaLabel={""}
                external={!!process.env.GATSBY_HOME_URL}
            >
                {jnjFullBranded && (
                    <Icon color="white" icon={jnjHome} type="jnj" size="medium" verticalAlignMiddle={true} />
                )}
                <Typography color="secondary" font={jnjFullBranded ? "text" : "title"} component="span" size="m">
                    {topBarName}
                </Typography>
            </JWMLink>
        ) : null;
    } else {
        return null;
    }
};
