import { useLocale } from "@jmc/core/src/hooks/useLocale/useLocale";
import { graphql, useStaticQuery } from "gatsby";

export interface ServiceInterface {
    isEnabled: boolean;
    url: string;
    title: string;
}

export interface SearchService extends ServiceInterface {
    noResultsExtraMessage?: string;
    imrNumber?: string;
    voiceEnabled?: boolean;
}

export enum ServiceTypes {
    RECIPE = "recipe",
    SEARCH = "search",
    ATE_QUESTIONS_AND_ANSWERS = "ateQuestionsAndAnswers",
}

export const useService = (service: ServiceTypes): ServiceInterface | null => {
    const locale = useLocale();

    const { allContentstackRecipeService, allContentstackSearchService, allContentstackAskTheExperts } = useStaticQuery(
        graphql`
            query {
                allContentstackRecipeService {
                    nodes {
                        uid
                        url
                        title
                        service_enabled
                        publish_details {
                            locale
                        }
                    }
                }
                allContentstackSearchService {
                    nodes {
                        uid
                        url
                        title
                        service_enabled
                        no_results_extra_message
                        imr_number
                        enable_voice_search
                        publish_details {
                            locale
                        }
                    }
                }
                allContentstackAskTheExperts {
                    nodes {
                        id
                        title
                        service_enabled
                        service_page_enabled
                        publish_details {
                            locale
                        }
                    }
                }
            }
        `,
    );

    const thisRecipeService = allContentstackRecipeService?.nodes?.filter((s) => {
        return s?.publish_details?.locale === locale;
    });

    const thisSearchService = allContentstackSearchService?.nodes?.filter((s) => {
        return s?.publish_details?.locale === locale;
    });

    const thisAteService = allContentstackAskTheExperts?.nodes?.filter((s) => {
        return s?.publish_details?.locale === locale;
    });

    const finalServices = {
        recipe: {
            isEnabled: thisRecipeService?.[0]?.service_enabled,
            url: thisRecipeService?.[0]?.url,
            title: thisRecipeService?.[0]?.title,
        } as ServiceInterface,
        search: {
            isEnabled: thisSearchService?.[0]?.service_enabled,
            url: thisSearchService?.[0]?.url,
            noResultsExtraMessage: thisSearchService?.[0]?.no_results_extra_message,
            imrNumber: thisSearchService?.[0]?.imr_number,
            title: thisSearchService?.[0]?.title,
            voiceEnabled: thisSearchService?.[0]?.enable_voice_search,
        } as SearchService,
        ateQuestionsAndAnswers: {
            isEnabled: thisAteService?.[0]?.service_enabled,
            title: thisAteService?.[0]?.title,
        } as ServiceInterface,
    };

    return finalServices[service];
};

export default useService;
