import axios, { AxiosInstance } from "axios";
import { AnyAction, applyMiddleware, createStore, Store } from "redux";
import thunk from "redux-thunk";

import { getEndpointUrl } from "../api/util";
import reducer from "./modules";

const createAxios = (): AxiosInstance => {
    const API_BASE = getEndpointUrl();
    if (!API_BASE) {
        throw new ReferenceError("NO API DEFINED");
    }
    // workaround for Axios issue: https://github.com/axios/axios/issues/1661
    axios.defaults.withCredentials = true;
    return axios.create({
        baseURL: API_BASE,
        headers: {
            "Content-Type": "application/json",
            "X-Country": process.env.GATSBY_COUNTRY_CODE,
            "X-ApiVersion": process.env.GATSBY_API_VERSION || "api",
        },
        validateStatus: (status) => status >= 200 && status < 400,
    });
};

/* eslint-disable @typescript-eslint/no-unused-vars */
class ThunkDispatch<T, U, V> {}

/**
 * Create the store
 */
export const create = (): Store & { dispatch: ThunkDispatch<Record<string, unknown>, AxiosInstance, AnyAction> } => {
    const storeFactory = applyMiddleware(thunk.withExtraArgument(createAxios()))(createStore);
    const store = storeFactory(
        reducer,
        (typeof window !== "undefined" &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line @typescript-eslint/no-explicit-any
            (window as any).__REDUX_DEVTOOLS_EXTENSION__()) || // eslint-disable-line @typescript-eslint/no-explicit-any
            undefined,
    );

    if (typeof window !== "undefined") {
        // make the store available for testing
        // eslint-disable-next-line
        (window as any).appStore = store;
    }

    return store;
};
