import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import React, { useState } from "react";

export interface PropTypes {
    open: boolean;
    type: string;
    name?: string;
    trackOnlyOnce?: boolean;
    children: JSX.Element;
}

interface EventInfo {
    type: string;
    name?: string;
    trigger: string;
    action?: string;
}

/**
 * Handles tracking on Modal components.
 *
 * It will:
 *  - Replace the onClose handler on the Modal with a new handler with tracking.
 *  - Recursively look inside all Modal children to replace onClick handlers with tracking.
 *
 * Then it will track the events:
 *  - NOTIFICATION_IMPRESION on modal load (everytime it loads or just the first one)
 *  - NOTIFICATION_CLICK on any click in the components of the modal.
 *
 */
export const ModalImpression = (props: PropTypes): JSX.Element => {
    const { children, open, type, name = "unset", trackOnlyOnce = false } = props;
    const { interacted } = useCookieInteraction();

    const [tracked, setTracked] = useState(false);
    let modal = children;

    const eventTracking = (event: string, action: string = null): void => {
        const info: EventInfo = { type: type, trigger: "user action" };
        if (action) info.action = action;
        if (name) info.name = name;

        analyticstracker().trackEvent({
            event: event,
            info: info,
        });
    };

    if (open && !tracked && interacted) {
        eventTracking(EventTypes.NOTIFICATION_IMPRESSION);
        if (trackOnlyOnce) setTracked(true);
    }

    const newHandler = (event: { apply: (arg0: unknown) => unknown }, action: string): unknown => {
        return () => {
            const value = event.apply(event);
            eventTracking(EventTypes.NOTIFICATION_CLICK, action);
            return value;
        };
    };

    // Recursively check all children to add tracking on clicks.
    const findClickableElements = (element: JSX.Element): JSX.Element => {
        // Look for onClick handlers
        if (element?.props?.onClick) {
            const onClick = element.props.onClick;
            const actionName = element?.props["data-tracking-action"] || element?.props?.children;
            element = React.cloneElement(element, { onClick: newHandler(onClick, actionName) });
        }

        let children = null;
        if (Array.isArray(element?.props?.children)) {
            children = element.props.children.map((elem: JSX.Element) => findClickableElements(elem));
        } else if (element?.props?.children) {
            children = findClickableElements(element?.props?.children);
        }

        return children ? React.cloneElement(element, { children }) : element;
    };

    // Track onClose event.
    const onClose = modal?.props?.onClose;
    if (onClose) {
        modal = React.cloneElement(children, { onClose: newHandler(onClose, "Close") });
    }

    return findClickableElements(modal);
};

export default ModalImpression;
