import createValueModule from "../create-value-module";

export interface VoiceSearchStatusState {
    profileId: string;
}

const { reducer, actions } = createValueModule("voiceSearchStatus", {
    isBrowserSupported: false,
    isListening: false,
} as VoiceSearchStatusState);

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
