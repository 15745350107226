import { CMSMinisite } from "@jwm/types/CMSMinisite";
import { default as getCustomColorsCSS } from "@utils/getCustomColorsCSS";
import React from "react";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    reference: CMSMinisite;
}

export const ColorWrapper = (props: PropTypes): JSX.Element => {
    const { children, reference } = props;
    const variables = getCustomColorsCSS(reference?.custom_colors);

    return <div style={variables}>{children}</div>;
};

export default ColorWrapper;
