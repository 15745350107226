import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {},
    // more languages are added later via addResourceBundle calls
    lng: process.env.GATSBY_FALLBACK_LOCALE,
    fallbackLng: process.env.GATSBY_FALLBACK_LOCALE,
    //debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false,
    },
    // useSuspense disabled, please check: https://github.com/gatsbyjs/gatsby/issues/20371
    react: {
        useSuspense: false,
    },
});

export default i18n;
