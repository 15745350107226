import { ApiStageConstants } from "@redux/api-stages-enum";
import { Options, State } from "@redux/create-api-module";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import { debug } from "loglevel";
import { Dispatch } from "redux";

/**
 * Returns a proper request object
 *
 * @param getPath
 * @param params
 * @param options
 */
export const createRequest = (
    getPath: (params: { [x: string]: object }) => string,
    params: { [x: string]: object },
    options: Options,
): AxiosRequestConfig => ({
    url: getPath(params),
    method: options.method,
    headers: params.headers,
    data: params.data,
});

interface ParamsTypes {
    headers?: { [x: string]: string };
    data?: { [x: string]: string | Record<string, unknown> };
}

/**
 * Create the load action
 *
 * DISCLAIMER: Omitting per method caching since backend is inconsistent
 * TODO: (AHAY-3440) Add support for cancellation when forcing a reload
 */
export const createLoadAction =
    (
        namespace: string,
        getPath: (params: { [x: string]: object }) => string,
        constants: ApiStageConstants,
        options: Options,
    ) =>
    (params: ParamsTypes = {}, force = false) =>
    (dispatch: Dispatch, getState: () => { [x: string]: State }, client: AxiosInstance): Promise<string> => {
        const state = getState()[namespace];
        // Merge with current params
        params = merge({}, state.params, params);
        // Skip if loading still
        if (state.loading) {
            debug(`Still Loading ${getPath}`);
            return Promise.resolve("LOADING");
        }
        // Skip if same
        if (!force && isEqual(state.params, params)) {
            return state.error ? Promise.reject(state.error) : Promise.resolve("SUCCESS");
        }
        // Dispatch loading action
        debug(`Loading ${getPath}`);
        dispatch({
            type: constants.PENDING,
            payload: params,
        });
        const request = createRequest(getPath, params, options);
        return client
            .request(request)
            .then((response: { data: object }) => {
                debug(`Loaded ${getPath}`);
                return dispatch({
                    type: constants.SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error: object) => {
                debug(`Failed Loading ${getPath}`);
                return dispatch({
                    type: constants.FAILURE,
                    payload: error,
                });
            });
    };
