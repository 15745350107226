/* eslint-disable react/jsx-no-target-blank */
import { LinkClickImpression } from "@jmc/core/src/components/LinkClickImpression/index";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Html } from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { graphql, useStaticQuery } from "gatsby";
import React, { Fragment, ReactNode } from "react";

import JWMLinkModal from "../JWMLinkModal/JWMLinkModal";

interface PropTypes {
    url: string;
    openModal: boolean;
    onClose: () => void;
    onClick: () => void;
    children: ReactNode;
}

interface DisclaimerProps {
    ariaLabel?: string;
    title?: string;
    url: string;
    openModal: boolean;
    onClose: () => void;
    onClick: () => void;
    children: ReactNode;
    className?: string;
    id?: string;
    imageLink?: boolean;
    name?: string;
    locale: string;
    placement?: string;
    tabIndex?: number;
    customExitDisclaimer?: CustomExitDisclaimer[];
}

interface CustomExitDisclaimer {
    title: string;
    text: string;
    ok_button_text: string;
    cancel_button_text: string;
    target_urls: string[];
}

interface ReduxProps {
    data: {
        external_link_disclaimer: {
            title: string;
            text: string;
            ok_button_text: string;
            cancel_button_text: string;
            whitelist_urls: string[];
        };
    };
}

interface CleanProps {
    className?: string;
    id?: string;
    title?: string;
}

export const Disclaimer: React.FunctionComponent<DisclaimerProps & ReduxProps> = ({
    ariaLabel,
    children,
    locale,
    openModal,
    url,
    onClose,
    onClick,
    data,
    customExitDisclaimer,
    imageLink,
    name,
    placement,
    tabIndex = 0,
    ...other
}: DisclaimerProps & ReduxProps): JSX.Element => {
    let displayModal = true;
    data?.external_link_disclaimer?.whitelist_urls?.forEach((whitelistUrl: string) => {
        if (url?.search(whitelistUrl.replace("*", "")) > -1) {
            displayModal = false;
        }
    });

    // Avoid passing wrong props to <a> element (like targetUrl)
    const cleanProps: CleanProps = {};
    if (other.className) cleanProps.className = other.className;
    if (other.id) cleanProps.id = other.id;
    if (other.title) cleanProps.title = other.title;

    if (!displayModal) {
        return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <LinkClickImpression
                name={name}
                targetUrl={url}
                imageLink={imageLink}
                external={true}
                placement={placement}
            >
                <a {...cleanProps} href={url} tabIndex={tabIndex} target="_blank" aria-label={ariaLabel}>
                    {children}
                </a>
            </LinkClickImpression>
        );
    }

    let disclaimerData = data?.external_link_disclaimer;
    customExitDisclaimer?.map((d) => {
        d?.target_urls?.forEach((targetUrl: string) => {
            if (url?.search(targetUrl.replace("*", "")) > -1) {
                disclaimerData = d;
            }
        });
    });

    return (
        <Fragment>
            <JWMLinkModal
                title={disclaimerData?.title || "External link"}
                url={url}
                open={openModal}
                onClose={onClose}
                OKButtonText={disclaimerData?.ok_button_text}
                CancelButtonText={disclaimerData?.cancel_button_text}
                locale={locale}
                type={"external link disclaimer"}
            >
                {disclaimerData?.text ? (
                    <div>
                        <Html>{disclaimerData?.text}</Html>
                    </div>
                ) : (
                    <Typography>
                        A new tab will open in your browser, which will show you the external content you requested.
                    </Typography>
                )}
            </JWMLinkModal>
            <LinkClickImpression
                name={name}
                targetUrl={url}
                imageLink={imageLink}
                external={true}
                placement={placement}
            >
                <a {...cleanProps} href={url} onClick={onClick} tabIndex={tabIndex} aria-label={ariaLabel}>
                    {children}
                </a>
            </LinkClickImpression>
        </Fragment>
    );
};

export const ExternalLinkDisclaimer: React.FunctionComponent<DisclaimerProps> = ({
    ...props
}: PropTypes): JSX.Element => {
    const { allContentstackDisclaimers, allContentstackExitDisclaimer } = useStaticQuery(
        graphql`
            query {
                allContentstackDisclaimers {
                    nodes {
                        external_link_disclaimer {
                            title
                            text
                            ok_button_text
                            cancel_button_text
                            whitelist_urls
                        }
                        publish_details {
                            locale
                        }
                    }
                }
                allContentstackExitDisclaimer {
                    nodes {
                        cancel_button_text
                        ok_button_text
                        text
                        title
                        target_urls
                        publish_details {
                            locale
                        }
                    }
                }
            }
        `,
    );
    const locale = useLocale();
    const localizedDisclaimer = allContentstackDisclaimers?.nodes
        .filter((disclaimer: { publish_details: { locale: string } }) => disclaimer.publish_details.locale === locale)
        .pop();
    const localizedExit = allContentstackExitDisclaimer?.nodes?.filter(
        (disclaimer: { publish_details: { locale: string } }) => disclaimer?.publish_details?.locale === locale,
    );

    return <Disclaimer data={localizedDisclaimer} customExitDisclaimer={localizedExit} locale={locale} {...props} />;
};
