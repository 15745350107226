import { default as SEO } from "@components/SEO/SEO";
import {
    JWM_VIDEO_ACCOUNT_ID,
    JWM_VIDEO_PLAYER_ID,
    JWM_VIDEO_PLAYER_INTERACTIVE_ID,
} from "@components/VideoPlayer/constants";
import stripSlash from "@jmc/utils/utils/strip-slash";
import { CMSPage } from "@jwm/types/CMSPage";
import { PageContext } from "@jwm/types/PageContext";

import { collectDeep } from "../../search/transformers/transformer-utils";
import { AccessLevel } from "../../types/AccessLevel";
import recipeSchema from "../recipeSchema";

/**
 * transform a CMSPage and PageContext instance into props for components
 */
export const mapSEOProps = (
    cmsPage: CMSPage,
    pageContext: PageContext,
    doNotIndex: boolean,
): React.ComponentProps<typeof SEO> => {
    // Add the domain to the page path before sending to the SEO component.
    const domain = stripSlash.stripTrailing(process.env.GATSBY_APP_DOMAIN);
    const pageUrl = stripSlash.stripTrailing(`${domain}/${pageContext?.page?.path}`);

    const openGraph = cmsPage?.seo_settings?.open_graph;
    if (openGraph) {
        openGraph.path = pageUrl;
    }

    // Collect all Faq blocks for one Schema to prevent duplicates
    const components = cmsPage?.content?.length && collectDeep(cmsPage?.content, {}, false, "component");
    const faqs = [];
    components?.component?.length &&
        components?.component?.forEach((c) => {
            if (c?.component?.[0]?.internal?.type === "Contentstack_component_faq") {
                //Collect Accordion FAQs
                if (c?.component?.[0]?.accordion && c?.component?.[0]?.accordion_content?.length) {
                    c?.component?.[0]?.accordion_content?.forEach(({ accordion }) => {
                        accordion?.panes.forEach(({ pane }) => {
                            faqs.push({
                                "@type": "Question",
                                name: pane?.question,
                                acceptedAnswer: {
                                    "@type": "Answer",
                                    text: pane?.answer,
                                },
                            });
                        });
                    });
                }

                //Collect Text FAQs
                if (!c?.component?.[0]?.accordion && c?.component?.[0]?.text_content?.length) {
                    c?.component?.[0]?.text_content?.forEach(({ text }) => {
                        faqs.push({
                            "@type": "Question",
                            name: text?.question,
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: text?.answer,
                            },
                        });
                    });
                }
            }
        });

    // Collect all videos from cmsPage data from players and video cards
    const players = cmsPage?.content?.length && collectDeep(cmsPage?.content, {}, "player");
    const videos = cmsPage?.content?.length && collectDeep(cmsPage?.content, {}, "video");
    const videoList = [];
    players?.player?.length &&
        players?.player.map((player) => {
            if (player) {
                let embedUrl = "";
                if (player?.related_to?.[0]?.media_source === "brightcove") {
                    embedUrl = `https://players.brightcove.net/${JWM_VIDEO_ACCOUNT_ID}/${
                        player?.related_to?.[0]?.interactive_video
                            ? JWM_VIDEO_PLAYER_INTERACTIVE_ID
                            : JWM_VIDEO_PLAYER_ID
                    }_default/index.html?${player?.related_to?.[0]?.video_id}`;
                }
                if (player?.related_to?.[0]?.media_source === "youtube") {
                    embedUrl = `https://www.youtube.com/watch?v=${player?.related_to?.[0]?.video_id}`;
                }

                videoList.push({
                    "@type": "VideoObject",
                    name: player?.related_to?.[0]?.title || "",
                    description: player?.related_to?.[0]?.summary || "",
                    uploadDate: player?.related_to?.[0]?.updated_at || "",
                    embedUrl,
                    thumbnailUrl: player?.related_to?.[0]?.poster_image?.url || "",
                });
            }
        });

    videos?.video?.length &&
        videos?.video.map((video) => {
            if (video) {
                let embedUrl = "";
                if (video?.[0]?.media_source === "brightcove") {
                    embedUrl = `https://players.brightcove.net/${JWM_VIDEO_ACCOUNT_ID}/${
                        video?.[0]?.interactive_video ? JWM_VIDEO_PLAYER_INTERACTIVE_ID : JWM_VIDEO_PLAYER_ID
                    }_default/index.html?${video?.[0]?.video_id}`;
                }
                if (video?.[0]?.media_source === "youtube") {
                    embedUrl = `https://www.youtube.com/watch?v=${video?.[0]?.video_id}`;
                }
                videoList.push({
                    "@type": "VideoObject",
                    name: video?.[0]?.title || "",
                    description: video?.[0]?.summary || "",
                    uploadDate: video?.[0]?.updated_at || "",
                    embedUrl,
                    thumbnailUrl: video?.[0]?.poster_image?.url || "",
                });
            }
        });
    return {
        title: cmsPage?.seo_settings?.meta_title || cmsPage?.page_title || cmsPage?.title || pageContext?.page?.title,
        siteSuffix: cmsPage?.site_reference?.[0]?.seo_settings?.site_suffix,
        siteSpecialty: cmsPage?.site_reference?.[0]?.related_to?.[0]?.title || "",
        metaTitle: cmsPage?.seo_settings?.meta_title,
        description: cmsPage?.seo_settings?.page_description,
        canonical: cmsPage?.seo_settings?.canonical || pageUrl || "",
        hreflangs: cmsPage?.seo_settings?.hreflang_associated_url,
        clonesHreflangs: pageContext?.hrefLangs,
        twitter: cmsPage?.seo_settings?.twitter,
        openGraph: openGraph,
        doNotIndexPage:
            cmsPage?.seo_settings?.do_not_index_page ||
            pageContext?.page?.do_not_index_page ||
            doNotIndex ||
            cmsPage?.access_control?.access_level === AccessLevel.Private ||
            cmsPage?.access_control?.access_level === AccessLevel.AccessCode,
        siteFallbackImage: cmsPage?.image?.url || cmsPage?.site_reference?.[0]?.seo_settings?.fallback_image?.url,
        ldJson: cmsPage?.recipe_details && recipeSchema(cmsPage),
        searchBox: cmsPage?.access_control?.access_level === AccessLevel.Public && cmsPage?.id !== "access-code",
        faqs: (faqs?.length && faqs) || [],
    };
};
