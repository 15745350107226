export interface ChildrenProps {
    showGlossary?: boolean;
    props?: { children?: { props?: { children?: object } }; showGlossary?: boolean };
}
/**
 * A function to clean up Glossary links where they should be avoided
 * @param children React component children
 * @returns Children with <GlossaryLink> components removed
 *
 * @deprecated Not in use after AHAY-4260. We either remove it or use it in other way.
 */
const cleanChildren = (
    children: string | ChildrenProps | ChildrenProps[],
): string | ChildrenProps | ChildrenProps[] => {
    if (children) {
        if (children?.props?.showGlossary) {
            return { ...children, props: { ...children?.props, showGlossary: false } };
        }
        if (Array.isArray(children) && children?.length) {
            const cleaned = children.map((c) => {
                return cleanChildren(c);
            });
            return cleaned;
        }
        if (Array.isArray(children?.props?.children) && children?.props?.children?.length) {
            const cleaned = children?.props?.children.map((c) => {
                return cleanChildren(c);
            });
            return { ...children, props: { ...children?.props, children: [...cleaned] } };
        }
        if (children?.props?.children?.props?.showGlossary) {
            return {
                ...children,
                props: {
                    ...children?.props,
                    children: {
                        ...children?.props?.children,
                        props: { ...children?.props?.children?.props, showGlossary: false },
                    },
                },
            };
        }
    }
    return children;
};

export function cleanGlossaryLink(
    children: string | ChildrenProps | ChildrenProps[],
): string | ChildrenProps | ChildrenProps[] {
    const cleaned = cleanChildren(children);
    return cleaned;
}
