import createValueModule from "../create-value-module";

export interface ContactRequestViewProfileState {
    profileId: string;
}

const { reducer, actions } = createValueModule("contactRequestViewProfile", {
    profileId: null,
} as ContactRequestViewProfileState);

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
