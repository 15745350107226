import classnames from "classnames";
import React, { useEffect } from "react";
import { keyHandler } from "@jmc/utils/utils/keyHandler";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";

import styles from "./style.module.scss";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

interface PropTypes {
    open: boolean;
    className?: string;
    openText?: string;
    closeText?: string;
    [x: string]: unknown;
}

/**
 * Hambuger icon with animation.
 */
export const Hamburger = ({ open = false, className, openText, closeText, ...other }: PropTypes): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();

    const handleKeyPress = (event: KeyboardEvent): void => {
        if (event.code == "Escape" && open) {
            // Close the menu and select the hamburger icon
            document.getElementById("Main.Menu.Toggle")?.focus();
            (other?.onClick as () => void)?.();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return (): void => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    });

    return (
        <a
            className={classnames(className, styles.container)}
            tabIndex={0}
            onKeyDown={(e: KeyboardEvent): void => keyHandler(e.key, () => other?.onClick())}
            role="button"
            {...other}
        >
            <div className={classnames(styles.hamburger, open ? styles.open : null)}>
                <span className={classnames(styles.bar, styles.bar1)} />
                <span className={classnames(styles.bar, styles.bar2)} />
                <span className={classnames(styles.bar, styles.bar3)} />
            </div>
            {openText && closeText && (
                <Typography
                    color={jnjFullBranded ? "inherit" : "primary-general"}
                    variant={jnjFullBranded ? "label-03" : undefined}
                    size={jnjFullBranded ? undefined : "xs"}
                    weight={jnjFullBranded ? "500" : undefined}
                >
                    {open ? closeText : openText}
                </Typography>
            )}
        </a>
    );
};

export default Hamburger;
