import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import style from "./style.module.scss";
import React from "react";
import classnames from "classnames";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

interface Props {
    className?: string;
    children?: React.ReactNode;
    copyright?: string;
    interestingLinks?: React.ReactNode;
    interestingLinksTitle?: string;
    logo?: React.ReactNode;
    xlPadding?: boolean;
    legalLinks?: JSX.Element[] | JSX.Element | string;
    socialMedia?: JSX.Element[] | JSX.Element | string;
    socialMediaTitle?: string;
}

export const Footer = ({
    children,
    className,
    copyright,
    interestingLinks,
    interestingLinksTitle,
    legalLinks,
    logo,
    xlPadding = true,
    socialMedia,
    socialMediaTitle,
}: Props) => {
    const { jnjFullBranded } = useJnjBranding();

    return (
        <div
            className={classnames(className, style.element, jnjFullBranded && xlPadding ? style.xlPadding : null)}
            data-test-id="footer.element"
        >
            {!jnjFullBranded && <div className={style.content}>{children}</div>}
            <div className={style.logo} data-test-id="footer.logo">
                {logo ? logo : null}
            </div>
            {jnjFullBranded && (
                <div className={style.content}>
                    {interestingLinks?.length ? (
                        <div className={style.interestingLinks}>
                            <Typography color="white" variant="h6" component="p" className={style.linksTitle}>
                                {interestingLinksTitle}
                            </Typography>
                            {interestingLinks}
                        </div>
                    ) : null}

                    {socialMedia?.props?.children?.length &&
                    socialMedia?.props?.children?.filter((c) => !!c)?.length > 0 ? (
                        <div className={style.social_media} data-test-id="footer.socialMediaIcons">
                            <Typography color="white" variant="h6" component="p" className={style.linksTitle}>
                                {socialMediaTitle}
                            </Typography>
                            {socialMedia}
                        </div>
                    ) : null}
                    <div className={style.text}>{children}</div>
                </div>
            )}
            {jnjFullBranded && (
                <div className={style.legal} data-test-id="footer.links">
                    <Typography className={style.copyright} variant="body-05" color="white">
                        {copyright}
                    </Typography>
                    {legalLinks}
                </div>
            )}
        </div>
    );
};
