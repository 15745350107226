import React from "react";
import { Helmet } from "react-helmet";

export const IssueCollector: React.FunctionComponent = () => (
    <Helmet>
        <script src="https://code.jquery.com/jquery-latest.js"></script>
        <script
            type="text/javascript"
            src="https://jira.jnj.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-8gi61a/805003/30b64d4b34d607d67c89627ab0b1525f/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=82c05cd1"
        ></script>
        <script
            type="text/javascript"
            src="https://jira.jnj.com/s/c672ab8f67d9affe6d677c09b81e9386-T/-8gi61a/805003/30b64d4b34d607d67c89627ab0b1525f/3.1.0/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=82c05cd1"
        ></script>
    </Helmet>
);

export default IssueCollector;
