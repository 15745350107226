import { useScript } from "@jmc/solid-design-system/src/hooks/useScript";
import React, { useEffect } from "react";
import yn from "yn";

export const EloquaTracking: React.FunctionComponent = (): JSX.Element => {
    if (!yn(process.env.GATSBY_ELOQUA_ENABLED)) {
        return null;
    }

    useEffect((): void => {
        const win = window;
        win._elqQ = win._elqQ || [];
        win._elqQ.push(["elqSetSiteId", process.env.GATSBY_ELOQUA_SITEID]);
        win._elqQ.push(["elqUseFirstPartyCookie", process.env.GATSBY_ELOQUA_TRACKING_DOMAIN]);
        win._elqQ.push(["elqTrackPageView", window.location.href]);
    }, []);

    useScript("//img.en25.com/i/elqCfg.min.js", "head", 3);

    return <></>;
};

export default EloquaTracking;
