import createValueModule from "../create-value-module";

export interface PageSpecialty {
    title: string | null;
}

const { reducer, actions } = createValueModule("pageSpecialty", {
    title: null,
} as PageSpecialty);

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
