import React from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import { default as MdiIcon } from "@mdi/react";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

export type IconColorsType =
    | "primary"
    | "secondary"
    | "white"
    | "black"
    | "success"
    | "error"
    | "inherit"
    | "primary-light"
    | "text-light"
    | "text-dark"
    | "text-grey"
    | "text-grey-300"
    | "text-gray-500"
    | "grey-light"
    | "green-light"
    | "disabled"
    | "primary-300"
    | "primary-500"
    | "primary-700"
    | "interactive-02"
    | "interactive-07"
    | "text-02";

type IconSize = string | "xxs" | "xs" | "small" | "medium" | "large" | "xl" | "xxl";

interface PropTypes {
    icon: string | string[] | object[];
    size?: IconSize;
    customSize?: {
        height: number;
        width: number;
    };
    color?: IconColorsType;
    verticalAlignMiddle?: boolean; // allows to tweak the vertical alignment of icons; some require it while others don't
    className?: string;
    type?: "mdi" | "jnj";
    id?: string;
}

// Icon for X (Twitter) because it is not in MDI library, must be loaded with same dimensions as MDI Icon
export const xTwitterIcon =
    "M13.5222 10.7749L19.4785 4H18.0671L12.8952 9.88256L8.76437 4H4L10.2466 12.8955L4 20H5.41155L10.8732 13.7878L15.2356 20H20L13.5218 10.7749H13.5222ZM11.5889 12.9738L10.956 12.0881L5.92015 5.03974H8.0882L12.1522 10.728L12.7851 11.6137L18.0677 19.0075H15.8997L11.5889 12.9742V12.9738Z";
export const jnjHome = [
    "M8.25 21.75H12.75V14.2575H8.25V21.75ZM20.2275 10.005C19.59 8.805 20.115 7.155 18.5625 6.1575C17.04 5.1825 15.66 5.9175 14.7225 6.4575L10.5 2.25L1.5 11.22V21.75H3V11.8575L10.5 4.3725L18 11.8725V21.75H20.88C20.655 21.2025 20.415 20.6025 20.2575 20.13C19.665 18.315 19.8 17.6925 20.3325 16.8675C21.24 15.4725 22.5675 15.0675 22.4925 13.5225C22.4025 11.5125 20.7825 11.055 20.2275 10.005Z",
];
export const jnjLock = [
    "M16.6643 9.85714H16.6V6.80714C16.5929 4.15714 14.4357 2 11.7857 2C9.13571 2 6.97857 4.15714 6.97857 6.80714V9.85714H5V15.4429C5.09286 19.1214 8.03571 22 11.7857 22C15.5357 22 18.5714 19.1214 18.5714 15.4429V9.85714H16.6643ZM9.12143 6.80714C9.12143 5.34286 10.3143 4.14286 11.7857 4.14286C12.4923 4.14286 13.17 4.42356 13.6696 4.92321C14.1693 5.42286 14.45 6.10053 14.45 6.80714V9.85714H9.11429V6.80714H9.12143ZM17.1429 15.4429C17.1429 15.9429 17.0714 16.4214 16.9429 16.8714C16.9429 16.8786 16.9429 16.8786 16.9357 16.8786C16.1929 18.8143 13.9929 19.8571 11.7857 19.8571C9.57857 19.8571 7.37857 18.8143 6.62857 16.8786V16.8714C6.49622 16.4068 6.42891 15.926 6.42857 15.4429V11.2857H17.1429V15.4429ZM11.0714 15.9071V17.9286H12.5V15.9071C12.8446 15.7447 13.1236 15.4698 13.291 15.1276C13.4585 14.7855 13.5044 14.3965 13.4212 14.0247C13.3381 13.653 13.1308 13.3206 12.8335 13.0824C12.5362 12.8442 12.1667 12.7144 11.7857 12.7143C11.4048 12.7144 11.0352 12.8442 10.7379 13.0824C10.4406 13.3206 10.2333 13.653 10.1502 14.0247C10.067 14.3965 10.1129 14.7855 10.2804 15.1276C10.4478 15.4698 10.7268 15.7447 11.0714 15.9071Z",
];
export const jnjArrowLeft = [
    "M4 11.9798L12.3809 4L13.5238 5.01266L8.02112 10.9671H20V12.9924H8.02112L13.4814 18.9468L12.3809 20L4 11.9798Z",
];
export const jnjArrowRight = [
    "M20 11.9798L11.6191 4L10.4762 5.01266L15.9789 10.9671H4V12.9924H15.9789L10.5186 18.9468L11.6191 20L20 11.9798Z",
];
export const jnjCheck = [
    "M8.31315 15.9753L3.63336 11.7559L2.40015 12.9783L8.3112 19.2L21.1805 6.02396L19.9566 4.79999L8.31315 15.9753Z",
];
export const jnjChevronDown = [
    "M12.0202 16.5238L20 8.14285L18.9873 7L12 13.2619L5.0532 7.04237L4 8.14285L12.0202 16.5238Z",
];
export const jnjChevronUp = ["M11.9798 7L4 15.3809L5.01266 16.5238L12 10.2619L18.9468 16.4814L20 15.3809L11.9798 7Z"];
export const jnjChevronLeft = ["M7 11.9798L15.3809 4L16.5238 5.01266L10.2619 12L16.4814 18.9468L15.3809 20L7 11.9798Z"];
export const jnjChevronRight = [
    "M16.5238 11.9798L8.14285 4L7 5.01266L13.2619 12L7.04237 18.9468L8.14285 20L16.5238 11.9798Z",
];
export const jnjDownload = [
    "M12.4111 17.4194L5.5306 10.3902L6.40375 9.43166L11.5379 14.0468V4H13.2843V14.0468L18.4184 9.4672L19.3265 10.3902L12.4111 17.4194ZM5 18.4516H19.8571V20H5V18.4516Z",
];
export const jnjPerson = [
    "M12 9.765C14.28 9.765 16.1325 7.9125 16.1325 5.6325C16.1325 3.3525 14.28 1.5 12 1.5C9.72001 1.5 7.86751 3.3525 7.86751 5.6325C7.86751 7.9125 9.72001 9.765 12 9.765ZM12 3C13.4475 3 14.6325 4.1775 14.6325 5.6325C14.6325 7.08 13.455 8.265 12 8.265C10.5525 8.265 9.36751 7.0875 9.36751 5.6325C9.37501 4.1775 10.5525 3 12 3ZM18.93 22.5C18.93 22.5 18.7875 17.1375 16.1925 14.5425L17.13 22.5H6.86251L7.80001 14.5425C5.21251 17.1375 5.07001 22.5 5.07001 22.5H2.07751C2.07751 22.5 2.40751 20.4975 3.09001 18.54C4.57501 14.295 7.09501 11.2275 12 11.2275C16.905 11.2275 19.425 14.3025 20.91 18.5475C21.5925 20.505 21.93 22.5075 21.93 22.5075H18.93V22.5Z",
];
export const jnjTime = [
    "M12.75 5.25H11.25V3.75H12.75V5.25ZM12.75 18.75H11.25V20.25H12.75V18.75ZM20.25 12.75V11.25H18.75V12.75H20.25ZM5.25 12.75V11.25H3.75V12.75H5.25ZM13.485 11.97L16.1775 8.64L15.3825 7.845L11.2275 11.205V12.7875L16.9275 17.6925L17.7225 16.8975L13.485 11.97ZM22.5 12C22.5 17.79 17.79 22.5 12 22.5C6.21 22.5 1.5 17.79 1.5 12C1.5 6.21 6.21 1.5 12 1.5C17.79 1.5 22.5 6.21 22.5 12ZM21 12C21 7.035 16.965 3 12 3C7.035 3 3 7.035 3 12C3 16.965 7.035 21 12 21C16.965 21 21 16.965 21 12Z",
];
export const jnjView = [
    "M21.2437 10.854C19.5337 9.07603 16.8712 5.74146 11.9962 5.74146C7.07622 5.74146 4.38372 9.14029 2.67372 10.904C2.31372 11.1539 1.96872 11.311 1.50372 11.311V11.6823V12.382V12.7533C1.96872 12.7533 2.31372 12.9104 2.67372 13.1603C4.38372 14.9169 7.03872 18.2586 11.9962 18.2586C16.1362 18.2586 19.3987 15.0097 21.2062 13.2317C21.6112 12.9247 21.9787 12.7462 22.4962 12.7462V12.3749V11.6751V11.3038C21.9862 11.311 21.6262 11.1396 21.2437 10.854ZM11.9812 16.3021C7.46622 16.3021 4.95372 13.6745 3.24372 12.0322C4.95372 10.3899 7.34622 7.69079 11.9812 7.69079C16.6162 7.69079 19.0087 10.3899 20.7187 12.0322C19.0087 13.6745 16.4962 16.3021 11.9812 16.3021ZM11.9962 8.46195C9.92622 8.46195 8.24622 10.0614 8.24622 12.0322C8.24622 14.0029 9.92622 15.6024 11.9962 15.6024C14.0662 15.6024 15.7462 14.0029 15.7462 12.0322C15.7462 10.0685 14.0662 8.46195 11.9962 8.46195ZM11.9962 14.5599C10.5337 14.5599 9.34872 13.4245 9.34872 12.0393C9.34872 10.6469 10.5412 9.51873 11.9962 9.51873C13.4587 9.51873 14.6437 10.6541 14.6437 12.0393C14.6437 13.4245 13.4587 14.5599 11.9962 14.5599Z",
];
export const jnjError = [
    "M2 12C2 6.479 6.479 2 12 2s10 4.479 10 10-4.479 10-10 10S2 17.521 2 12m1.429 0c0 4.729 3.842 8.571 8.571 8.571s8.571-3.85 8.571-8.571S16.73 3.429 12 3.429 3.429 7.27 3.429 12m7.819 1h1.5c0-3.045.742-6 .742-6h-2.985s.742 2.955.742 6m.757.99a1.365 1.365 0 1 0 0 2.73 1.365 1.365 0 0 0 0-2.73",
];
export const jnjWarning = [
    "M12.0075 15.99C11.25 15.99 10.6425 16.605 10.6425 17.355C10.6425 18.105 11.2575 18.72 12.0075 18.72C12.7575 18.72 13.3725 18.105 13.3725 17.355C13.3725 16.605 12.765 15.99 12.0075 15.99ZM11.25 15H12.75C12.75 11.955 13.4925 9 13.4925 9H10.5075C10.5075 9 11.25 11.955 11.25 15ZM12.7575 2.25H11.2425L1.5 20.655L2.16 21.75H21.8475L22.5 20.655L12.7575 2.25ZM12 20.25H3.4125L12 4.0425L20.5875 20.25H12Z",
];
export const jnjHelp = [
    "M20.5714 12C20.5714 7.26571 16.7343 3.42857 12 3.42857C7.26571 3.42857 3.42857 7.26571 3.42857 12C3.42857 16.7343 7.26571 20.5714 12 20.5714C16.7343 20.5714 20.5714 16.7343 20.5714 12ZM4.92857 4.92857C6.81714 3.04 9.32857 2 12 2C14.6714 2 17.1829 3.04 19.0714 4.92857C20.96 6.81714 22 9.32857 22 12C22 14.6714 20.96 17.1829 19.0714 19.0714C17.1829 20.96 14.6714 22 12 22C9.32857 22 6.81714 20.96 4.92857 19.0714C3.04 17.1829 2 14.6714 2 12C2 9.32857 3.04 6.81714 4.92857 4.92857ZM16.2857 9.08426C16.2857 6.82998 14.1686 5.92712 12.1729 5.92712C9.74286 5.92712 7.73143 7.24569 7.71429 9.89855L9.95429 10.1071C9.95429 8.8114 10.4743 7.34998 12.1214 7.34998C13.3629 7.34998 14.0886 8.04855 14.0886 8.84283C14.0886 9.90135 13.3947 10.5031 12.6616 11.139C11.769 11.9131 10.8182 12.7378 10.99 14.4985H12.4014C12.3612 13.3837 13.2131 12.8102 14.1291 12.1936C15.1663 11.4954 16.2857 10.7418 16.2857 9.08426ZM11.6886 15.9286C11.0486 15.9286 10.6229 16.3814 10.6229 17.0071C10.6229 17.6457 11.0486 18.0714 11.6886 18.0714C12.3157 18.0714 12.7672 17.6457 12.7672 17.0071C12.7672 16.3829 12.3143 15.9286 11.6886 15.9286Z",
];
export const jnjVideoPlay = [
    "M7.32825 5.81323L6 6.6579V18.9686L7.32825 19.8132L18.8333 13.7786V11.9686L7.32825 5.81323Z",
];
export const jnjCheckCircle = [
    "M12 1.5C6.2025 1.5 1.5 6.2025 1.5 12C1.5 17.7975 6.2025 22.5 12 22.5C17.7975 22.5 22.5 17.7975 22.5 12C22.5 6.2025 17.7975 1.5 12 1.5ZM12 21C7.035 21 3 16.965 3 12C3 7.035 7.035 3 12 3C16.965 3 21 7.0425 21 12C21 16.9575 16.965 21 12 21ZM9.42 13.035C8.4075 12.4725 7.095 11.9625 5.415 11.6775C5.295 11.955 5.31 11.9175 5.1975 12.1875C5.9625 12.78 7.0425 13.77 7.9275 15.1875C8.31 15.8025 8.67 16.5375 8.985 17.3475C9.9075 17.34 9.9375 17.355 10.86 17.3475C11.205 16.425 11.565 15.5475 11.8575 14.9775C13.155 12.3975 15.6075 9.39 17.835 7.3725L17.5425 6.8925C13.1025 8.5425 10.905 11.22 9.42 13.035Z",
];
export const jnjInfo = [
    "M12 2C6.47857 2 2 6.47857 2 12C2 17.5214 6.47857 22 12 22C17.5214 22 22 17.5214 22 12C22 6.47857 17.5214 2 12 2ZM12 20.5714C7.27143 20.5714 3.42857 16.7286 3.42857 12C3.42857 7.27143 7.27143 3.42857 12 3.42857C16.7286 3.42857 20.5714 7.27857 20.5714 12C20.5714 16.7214 16.7286 20.5714 12 20.5714ZM12.0214 9.56429C12.28 9.56429 12.5327 9.48762 12.7476 9.34399C12.9626 9.20036 13.1301 8.99621 13.2291 8.75737C13.328 8.51852 13.3539 8.25569 13.3035 8.00213C13.253 7.74857 13.1285 7.51566 12.9457 7.33285C12.7629 7.15005 12.53 7.02555 12.2764 6.97512C12.0229 6.92468 11.7601 6.95057 11.5212 7.0495C11.2824 7.14844 11.0782 7.31597 10.9346 7.53093C10.7909 7.74589 10.7143 7.99861 10.7143 8.25714C10.7143 8.60382 10.852 8.9363 11.0971 9.18143C11.3423 9.42657 11.6748 9.56429 12.0214 9.56429ZM12.8071 15.8929C11.9929 15.8929 12 15.3286 12.2143 14.5L12.8571 12L13.2286 10.5714H9.15V12H10.6357L9.92143 14.7714C9.65 15.7286 9.85714 17.1 11.8 17.1C13.05 17.1 13.7 16.4071 14.0429 15.7286C13.8643 15.5929 13.9071 15.6214 13.7286 15.4929C13.4929 15.7143 13.1857 15.8929 12.8071 15.8929Z",
];
export const jnjCloseNaked = [
    "M13.5058,11.992L18.9999,6.50404L17.4942,5L12,10.4879L6.50582,5L5.00006,6.50404L10.4942,11.992L5,17.48L6.50576,18.984L12,13.496L17.4943,18.984L19,17.48L13.5058,11.992Z",
];
export const jnjExternalLink = [
    "M15.48,7.14143V4L20.8045,9.34443L15.48,14.6955V11.5607C11.4867,11.5607,7.49334,11.7737,7.49334,17.3378H6.16223C6.16223,9.87022,10.7346,7.3411,15.48,7.14143ZM16.8111,18.6689H4.83111V6.02329H10.8211V4.69218H3.5V20H18.1423V15.3411H16.8111V18.6689Z",
];
export const jnjPlay = ["M7.32825 5L6 5.84467V18.1553L7.32825 19L18.8333 12.9654V11.1553L7.32825 5Z"];
export const jnjPause = ["M9.57291 19V5H7.21503V19H9.57291ZM16.7849 19V5H14.4269V19H16.7849Z"];
export const jnjReplay = [
    "M21.735 12.75C21.7275 17.9325 17.34 22.305 12.0825 22.305C6.63755 22.305 2.14505 17.85 2.27255 12.39V12.3825C2.27255 7.83751 5.58005 3.70501 10.665 2.87251V0.695007L13.485 4.51501L10.665 8.33501V6.09001C7.96505 6.24751 4.06505 10.0125 4.96505 14.5125C5.54255 17.415 9.39005 21.5475 14.5575 20.1975C17.7975 19.185 19.995 16.0425 20.055 12.7425H21.735V12.75Z",
];
export const jnjCopy = [
    "M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM15.5 5H8.5C7.4 5 6.51 5.9 6.51 7L6.5 21C6.5 22.1 7.39 23 8.49 23H19.5C20.6 23 21.5 22.1 21.5 21V11L15.5 5ZM8.5 21V7H14.5V12H19.5V21H8.5Z",
];
export const jnjFilter = [
    {
        d: "M16.7999 6.45005C16.7999 7.11279 16.2626 7.65006 15.5999 7.65006C14.9371 7.65006 14.3999 7.11279 14.3999 6.45005C14.3999 5.78731 14.9371 5.25005 15.5999 5.25005C16.2626 5.25005 16.7999 5.78731 16.7999 6.45005ZM17.9243 7.05005C17.6579 8.08521 16.7182 8.85006 15.5999 8.85006C14.4815 8.85006 13.5419 8.08521 13.2755 7.05005H13.1999H4.19985H3.59985V5.85005H4.19985H13.1999H13.2755C13.5419 4.81491 14.4815 4.05005 15.5999 4.05005C16.7182 4.05005 17.6579 4.81491 17.9243 5.85005H17.9999H19.7999H20.3999V7.05005H19.7999H17.9999H17.9243ZM4.19985 11.2501H3.59985V12.4501H4.19985H5.99985H6.07547C6.34189 13.4852 7.28155 14.2501 8.39985 14.2501C9.51818 14.2501 10.4579 13.4852 10.7243 12.4501H10.7999H19.7999H20.3999V11.2501H19.7999H10.7999H10.7243C10.4579 10.2149 9.51818 9.45006 8.39985 9.45006C7.28155 9.45006 6.34189 10.2149 6.07547 11.2501H5.99985H4.19985ZM4.19985 16.6501H3.59985V17.8501H4.19985H13.1999H13.2755C13.5419 18.8852 14.4815 19.6501 15.5999 19.6501C16.7182 19.6501 17.6579 18.8852 17.9243 17.8501H17.9999H19.7999H20.3999V16.6501H19.7999H17.9999H17.9243C17.6579 15.6149 16.7182 14.8501 15.5999 14.8501C14.4815 14.8501 13.5419 15.6149 13.2755 16.6501H13.1999H4.19985ZM15.5999 18.4501C16.2626 18.4501 16.7999 17.9128 16.7999 17.2501C16.7999 16.5873 16.2626 16.0501 15.5999 16.0501C14.9371 16.0501 14.3999 16.5873 14.3999 17.2501C14.3999 17.9128 14.9371 18.4501 15.5999 18.4501ZM9.59985 11.8501C9.59985 12.5128 9.06263 13.0501 8.39985 13.0501C7.73708 13.0501 7.19985 12.5128 7.19985 11.8501C7.19985 11.1873 7.73708 10.6501 8.39985 10.6501C9.06263 10.6501 9.59985 11.1873 9.59985 11.8501Z",
        fillRule: "evenodd",
        clipRule: "evenodd",
    },
];
export const jnjSearch = [
    "M9.12681 3.01405C10.2579 3.01405 11.3609 3.32318 12.3234 3.91332C13.2854 4.5017 14.0678 5.34241 14.5857 6.34416C15.1 7.34733 15.3285 8.47267 15.2461 9.597C15.1618 10.7211 14.7683 11.803 14.115 12.7163C13.9182 13.0044 13.9182 13.2503 14.1782 13.44C15.506 14.3884 16.4826 13.5243 17.9088 14.9505L21 18.0558L18.0212 21.0346L14.9018 17.9082C13.4686 16.475 14.3679 15.5266 13.4265 14.1917C13.2297 13.9388 12.9909 13.9247 12.7028 14.1285C11.916 14.6905 11.0097 15.0559 10.0542 15.2034C9.09871 15.3509 8.12216 15.2666 7.20884 14.9645C6.29186 14.6609 5.45916 14.1459 4.77799 13.4611C4.09652 12.7725 3.59068 11.9365 3.2956 11.0162C3.00053 10.0958 2.92325 9.11926 3.07781 8.16378C3.23145 7.20963 3.60684 6.30485 4.1738 5.52217C4.74252 4.74006 5.48901 4.10433 6.35172 3.66743C7.21402 3.23013 8.16699 3.00152 9.13384 3L9.12681 3.01405ZM9.12681 13.8966C10.0663 13.8971 10.9849 13.619 11.7665 13.0976C12.548 12.5761 13.1575 11.8347 13.5178 10.967C13.8789 10.1009 13.9719 9.14648 13.7848 8.22701C13.6021 7.30666 13.1525 6.4636 12.485 5.79617C11.8176 5.13577 10.9745 4.67911 10.0542 4.49644C9.13167 4.31205 8.17511 4.40746 7.30719 4.77044C6.4406 5.13029 5.69993 5.73865 5.17857 6.51882C4.65721 7.29899 4.37848 8.21605 4.37754 9.15439C4.37754 10.412 4.87635 11.6204 5.7686 12.5056C6.66107 13.3935 7.86788 13.8934 9.12681 13.8966Z",
];
export const jnjHistory = [
    "M21.735 12.7499C21.7275 17.9324 17.34 22.3049 12.0825 22.3049C6.63749 22.3049 2.14499 17.8499 2.27249 12.3899V12.3824C2.27249 7.83745 5.57999 3.70495 10.665 2.87245V1.69495L13.485 4.51495L10.665 7.33495V6.08995C7.96499 6.24745 4.06499 10.0124 4.96499 14.5124C5.54249 17.4149 9.38999 21.5474 14.5575 20.1974C17.7975 19.1849 19.995 16.0424 20.055 12.7424H21.735V12.7499ZM21.4125 11.4374C21.33 10.9274 21.2025 10.4174 21.0375 9.92245L19.515 10.4324C19.65 10.8449 19.755 11.2649 19.8225 11.6924L21.4125 11.4374ZM20.46 8.55745C20.22 8.09245 19.9425 7.64995 19.6275 7.23745L18.345 8.19745C18.6 8.54245 18.8325 8.90995 19.035 9.29995L20.46 8.55745ZM18.645 6.11995C18.27 5.75995 17.865 5.42245 17.4375 5.12995L16.5225 6.44995C16.875 6.69745 17.2125 6.97495 17.5275 7.27495L18.645 6.11995ZM16.155 4.37995C15.69 4.15495 15.195 3.96745 14.7 3.81745L14.25 5.35495C14.6625 5.47495 15.075 5.63245 15.465 5.81995L16.155 4.37995ZM12.84 12.2324L12.39 8.60245H11.0775L10.425 13.9124H10.4325L11.2275 14.7074L18.7275 14.0549V12.7499L12.84 12.2324Z",
];
export const jnjShoppingBasket = [
    "M18.75 18.3673C17.925 18.3673 17.25 19.0423 17.25 19.8673C17.25 20.6923 17.925 21.3673 18.75 21.3673C19.575 21.3673 20.25 20.6923 20.25 19.8673C20.25 19.0423 19.575 18.3673 18.75 18.3673ZM8.25 18.3673C7.425 18.3673 6.75 19.0423 6.75 19.8673C6.75 20.6923 7.425 21.3673 8.25 21.3673C9.075 21.3673 9.75 20.6923 9.75 19.8673C9.75 19.0423 9.075 18.3673 8.25 18.3673ZM22.5 5.61733H6.99L6.8925 4.74733C6.83061 4.16613 6.55573 3.62843 6.12088 3.23789C5.68602 2.84735 5.12198 2.63163 4.5375 2.63233H1.5V4.10983H4.5375C4.995 4.10983 5.3775 4.45483 5.43 4.91233L6.6075 15.5023C6.67098 16.0842 6.94735 16.6221 7.38346 17.0125C7.81958 17.403 8.38465 17.6184 8.97 17.6173H20.25V16.1398H8.9625C8.74107 16.1393 8.52761 16.0571 8.36295 15.9091C8.19829 15.761 8.09399 15.5575 8.07 15.3373L7.9875 14.6248H21L22.5 5.61733ZM10.0425 13.1398H8.505C8.13 13.1398 7.8075 12.8548 7.77 12.4798L7.26 7.91983C7.2075 7.47733 7.5525 7.09483 7.995 7.09483H10.0425V13.1398ZM13.5525 13.1398H10.95V7.09483H13.5525V13.1398ZM16.7925 13.1398H14.46V7.09483H16.7925V13.1398ZM19.8525 12.5248C19.824 12.699 19.7343 12.8573 19.5996 12.9714C19.4649 13.0855 19.294 13.1479 19.1175 13.1473H17.7V7.09483H20.7525L19.8525 12.5248Z",
];
export const jnjMeal = [
    {
        d: "M13.1925 4.9725V12.555C13.6725 12.555 14.385 12.855 14.355 13.59C14.355 13.59 14.0625 20.88 14.0625 22.4925H16.665V1.5C14.745 1.5 13.1925 3.0525 13.1925 4.9725Z",
        fillRule: "evenodd",
        clipRule: "evenodd",
    },
    {
        d: "M11.445 1.515C11.445 1.5075 11.4375 1.5 11.4375 1.5H10.6875C10.6875 1.5075 10.68 1.515 10.68 1.5225C10.68 1.5225 10.575 6.7275 10.56 7.71H9.95252L9.88502 1.5225C9.88502 1.515 9.87752 1.5075 9.87752 1.5H9.09002C9.09002 1.5075 9.08252 1.515 9.08252 1.5225C9.08252 1.5225 8.97752 6.7275 8.96252 7.71H8.35502L8.28752 1.5225C8.28752 1.515 8.28002 1.5075 8.28002 1.5H7.47752C7.47752 1.5075 7.47002 1.515 7.47002 1.5225L7.33502 7.785V8.3925C7.32752 9.03 7.55252 9.615 7.92752 10.0125L8.73002 10.815L8.00252 22.5H10.6725L10.08 10.8225L10.9125 10.0275C11.2875 9.6675 11.5125 9.06 11.52 8.4225V7.815L11.445 1.515Z",
    },
];
export const jnjCooking = [
    "M8.89425 12.0369C8.90677 12.8973 8.89102 14.0952 8.91885 14.9656C8.982 16.549 10.4214 17.8875 12 17.8473C13.5788 17.8878 15.0178 16.5488 15.0811 14.9657C15.1079 14.0938 15.0931 12.8989 15.1057 12.0369C13.2831 12.0749 10.7169 12.0751 8.89425 12.0369ZM13.7168 14.8905C13.7006 15.7886 12.8973 16.5546 12 16.5534C11.1026 16.5547 10.2995 15.7885 10.2832 14.8905C10.276 14.672 10.2796 14.2456 10.2841 13.8168C10.8291 14.0506 11.3972 14.1475 12 14.1713C12.6079 14.1726 13.1759 14.0846 13.7174 13.8416C13.7218 14.2634 13.7248 14.6793 13.7168 14.8905Z",
    "M20.9836 13.3539C20.8297 13.0365 20.8144 12.6227 20.812 12.276C20.8066 11.5187 20.2887 10.7863 19.6857 10.3719C19.3925 10.1705 19.0596 10.0256 18.7103 9.95607C18.2698 9.86839 17.8176 9.8954 17.3752 9.812C16.3765 9.62375 15.6517 8.75 15.6517 7.73487V5.35227H14.7874L14.4322 1.52832H13.3413L12.9862 5.35227H11.0137L10.6586 1.52832H9.56775L9.21254 5.35227H8.34817V7.73487C8.34817 8.49837 7.9209 9.21905 7.25212 9.58707C6.95505 9.75057 6.62122 9.83892 6.28657 9.88272C5.94735 9.92712 5.60272 9.89262 5.26717 9.96312C4.87777 10.0449 4.50809 10.2175 4.19144 10.458C3.77429 10.7749 3.44647 11.2152 3.29894 11.7207C3.10822 12.241 3.26497 12.8487 3.01649 13.3539C2.87039 13.7604 2.34704 14.2088 2.05334 14.5169L2.81999 15.3048C3.29647 14.9256 3.91484 14.4597 4.18934 13.8966C4.53059 13.3912 4.50127 12.7349 4.70887 12.1879C4.97729 11.7178 5.37944 11.5954 5.88509 11.6879L6.07514 11.7057V20.8525L6.42195 21.0451C7.2309 21.4956 8.10652 21.8449 9.02242 22.0851C9.99322 22.3415 10.9948 22.4718 12.0001 22.4718C13.0053 22.4718 14.0069 22.3415 14.9763 22.0859C15.8936 21.8449 16.7692 21.4956 17.5782 21.0451L17.925 20.8525V11.7017C18.136 11.6811 18.3292 11.662 18.3925 11.6562C18.8845 11.6132 19.3555 12.0477 19.41 12.5583C19.4596 13.7284 20.2647 14.6659 21.1801 15.3049L21.9467 14.5169C21.6571 14.2128 21.128 13.7583 20.9836 13.3539ZM16.5751 19.299C15.9635 19.6067 15.312 19.852 14.6327 20.03C12.9166 20.4826 11.0848 20.4834 9.36584 20.0292C8.68762 19.852 8.03647 19.6066 7.42492 19.299V10.9875C8.77035 10.4954 9.69802 9.20555 9.69802 7.73487V6.70287H14.302V7.73487C14.302 9.20555 15.2296 10.4954 16.5751 10.9875L16.5751 19.299Z",
];
export const jnjMail = [
    "M 22.5 6.75 C 22.5 4.875 21.234375 3.75 19.710938 3.75 L 4.289062 3.75 C 2.773438 3.75 1.5 4.882812 1.5 6.75 L 1.5 20.25 L 22.5 20.25 Z M 3.039062 7.972656 C 3.039062 7.265625 3.644531 6.953125 4.074219 6.953125 C 4.335938 6.953125 4.566406 7.058594 4.769531 7.253906 L 8.675781 11.453125 L 3.039062 17.503906 Z M 14.273438 12.585938 L 19.988281 18.703125 L 4.019531 18.703125 L 9.726562 12.585938 L 12 15.023438 Z M 15.324219 11.453125 L 19.230469 7.253906 C 19.433594 7.050781 19.664062 6.953125 19.925781 6.953125 C 20.355469 6.953125 20.960938 7.265625 20.960938 7.972656 L 20.960938 17.496094 Z M 18.96875 5.289062 L 12 12.765625 L 5.03125 5.289062 Z M 18.96875 5.289062 ",
];

/**
 * Icon component
 *
 * Can use icons from :
 * - React Mdi library (https://pictogrammers.com/library/mdi/)
 * - JnJ branded icons (https://brandcenter.jnj.com/document/444)
 *
 */
export const Icon = ({
    icon,
    size = "medium",
    customSize,
    color = "primary",
    verticalAlignMiddle = false,
    className,
    type = "mdi",
    ...other
}: PropTypes): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();

    const sizeMap = new Map<IconSize, string>([
        ["xxs", "0.5rem"],
        ["xs", "1rem"],
        ["small", "1.25rem"],
        ["medium", "1.5rem"],
        ["large", "1.75rem"],
        ["xl", "3rem"],
        ["xxl", "6rem"],
        ["inherit", "1.25em"],
    ]);

    const height = customSize ? customSize.height : sizeMap.get(size) || size;
    const width = customSize ? customSize.width : height;

    return (
        <span
            className={classnames(
                style.element,
                verticalAlignMiddle && style.verticalAlignMiddle,
                color && style[color],
                className,
            )}
            style={jnjFullBranded ? { width, height } : undefined}
            {...other}
        >
            {type === "mdi" ? (
                <MdiIcon
                    path={icon}
                    size={width}
                    title={other?.id}
                    //Here we are giving the description as an empty whitespace to prevent the NVDA reader from reading the text(id) twice
                    description={other?.id ? " " : null}
                />
            ) : (
                <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    {icon?.length > 0 &&
                        (icon as string[])?.map((path: string | object, index: number) => {
                            const props = typeof path === "object" ? path : { d: path };
                            return <path {...props} key={index} style={{ fill: "currentColor" }} />;
                        })}
                </svg>
            )}
        </span>
    );
};

Icon.displayName = "Icon";
