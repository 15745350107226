import JWMLink from "@components/JWMLink/JWMLink";
import JNJBarImpression from "@jmc/core/src/components/JNJBarImpression/JNJBarImpression";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import JNJBar from "@jmc/solid-design-system/src/components/atoms/JNJBar/JNJBar";
import { CMSJNJBar } from "@jwm/types/CMSJNJBar";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export const JWMJNJBar = (props: {
    withJNJBar?: boolean;
    setWithJNJBar?: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    const locale = useLocale() || "en-us";
    const { withJNJBar, setWithJNJBar } = props;

    const { query }: { query: { nodes: [CMSJNJBar] } } = useStaticQuery(graphql`
        {
            query: allContentstackJnjBar {
                nodes {
                    ...jnjBarFields
                }
            }
        }
    `);
    const jnjBar = query?.nodes?.filter((item) => item?.publish_details?.locale === locale)?.[0];
    const links = jnjBar?.links?.map((link, index) => {
        const url = link?.external_link || link?.internal_page?.[0]?.url;
        const url_prefix = link?.internal_page?.[0]?.site_reference?.[0]?.url_prefix;
        const title = link?.label;
        const external = link?.external_link ? true : false;

        return (
            <JWMLink
                id={`jnj-bar-link-${index}`}
                name={title}
                key={`${title}.${index}`}
                url={url}
                url_prefix={url_prefix}
                fullWidth={false}
                external={external}
            >
                {title}
            </JWMLink>
        );
    });

    return withJNJBar ? (
        <JNJBarImpression>
            <JNJBar text={jnjBar?.text} links={links} setWithJNJBar={setWithJNJBar} />
        </JNJBarImpression>
    ) : (
        <JNJBar text={jnjBar?.text} links={links} setWithJNJBar={setWithJNJBar} />
    );
};

export default JWMJNJBar;
