import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import path from "@jmc/utils/utils/path";
import { graphql, useStaticQuery } from "gatsby";
import { ServiceProps as ATEServiceProps } from "templates/services/ask-the-experts/types";

interface ServicesQuery {
    ateService: { nodes: [ATEServiceProps] };
}

/**
 * Return the URL to a given service page.
 *
 * A static query is used to retrieve the URLs of the service pages.
 * Currently, only ATE service is mapped, but more services can be added
 * to the query and to the Button Block global field in Content Stack.
 *
 * @param servicePage Service page key as defined in CS button block
 * @param specialty Specialty name
 * @returns URL to the service page.
 */
const getServicePageUrl = (servicePage: string, specialty?: string): string => {
    const locale = useLocale();
    let url = "";

    const query: ServicesQuery = useStaticQuery(graphql`
        {
            ateService: allContentstackAskTheExperts {
                nodes {
                    ...askTheExpertsFields
                }
            }
        }
    `);

    const ateService = query?.ateService?.nodes
        .filter((item: ATEServiceProps) => {
            return item?.publish_details?.locale == locale ? true : false;
        })
        .pop();

    const appendSpecialty = (specialty: string): string => {
        let queryString = "";
        const params = new URLSearchParams();
        if (specialty) {
            params.append("specialty", specialty);
            queryString = "?" + params.toString();
        }

        return queryString;
    };

    switch (servicePage) {
        case "ate_main":
            url = ateService?.url;
            break;

        case "ate_form":
            url = path.join(ateService?.url, ateService?.service_urls?.ask_question) + appendSpecialty(specialty);
            break;

        case "ate_questions":
            url = path.join(ateService?.url, ateService?.service_urls?.questions) + appendSpecialty(specialty);
            break;
    }

    return url;
};

export default getServicePageUrl;
