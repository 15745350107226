import { JWMLink } from "@components/JWMLink/JWMLink";
import { GlossaryLink } from "@jmc/core/src/components/GlossaryLink/GlossaryLink";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { Sizes, Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { TransformFunction } from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { Html } from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { Tooltip } from "@jmc/solid-design-system/src/components/molecules/Tooltip/Tooltip";
import { cleanGlossaryLink } from "@jmc/utils/utils/cleanGlossaryLink";
import { spaceCleaner } from "@jmc/utils/utils/space-cleaner";
import { CMSFootnotes, Footnote } from "@jwm/types/CMSFootnotes";
import { ApplicationState } from "@redux/modules/index";
import { default as getCustomColorsCSS } from "@utils/getCustomColorsCSS";
import { default as scrollIntoView } from "@utils/scrollIntoView";
import classnames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React, { ClassAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useSiteColors } from "../../contexts/SiteContext";
import style from "./style.module.scss";

export interface PropTypes extends ClassAttributes<React.FunctionComponent> {
    children: string;
    [x: string]: unknown;
    placement?: string;
    siteSpecialty?: {
        type: string;
        title: string;
    };
    showGlossary?: boolean;
    loading?: "lazy" | "eager";
    ignoreFootNote?: boolean;
    size?: Sizes;
    useLargeText?: boolean;
}

export const JWMHtml = ({
    children,
    placement,
    siteSpecialty,
    showGlossary = true,
    ignoreFootNote = false,
    loading = "lazy",
    size = undefined,
    useLargeText = false,
    ...other
}: PropTypes): JSX.Element => {
    const { t } = useTranslation();
    const locale = useLocale();
    const siteColors = useSiteColors();
    const extraStyles = getCustomColorsCSS(siteColors);
    const footnotes = useSelector((state: ApplicationState) => state.footnotes);

    const query = useStaticQuery(
        graphql`
            query {
                glossary: allContentstackGlossary {
                    nodes {
                        abbreviation
                        description
                        modal_title
                        specialties {
                            title
                            type
                        }
                        term
                    }
                }
            }
        `,
    );

    const createGlossary = (term: string, nodes: string): string => {
        try {
            const regex = new RegExp(
                `(?<pre>^|[\\s,.:;"'«‹‘“”„(<>])(?<term>${term})(?=[\\s,.:;"'»›’“”„)<>]|$)(?![^<]*>|[^<]*<\\/glossary>)`,
                "gimu",
            );
            return nodes?.replace(regex, `$<pre><glossary>$<term></glossary>`) || nodes;
        } catch (e) {
            // In case there is an error with RegExp, return nodes to prevent page breaking
            return nodes;
        }
    };

    // Sort terms to prevent splitting up overlapping glossaries
    const sortedGlossary =
        (query?.glossary?.nodes?.length &&
            query?.glossary?.nodes.sort((a, b) => {
                return b.term.length - a.term.length;
            })) ||
        [];

    let processedChildren = children;

    showGlossary &&
        sortedGlossary?.length &&
        sortedGlossary.map((g) => {
            const containsSpecialty =
                g?.specialties?.length &&
                g?.specialties.find((s) => {
                    return s?.title === siteSpecialty?.title;
                });
            if (!g?.specialties?.length || containsSpecialty) {
                if (g?.term) processedChildren = createGlossary(g.term, processedChildren);
                if (g?.abbreviation && g?.abbreviation?.split(",")?.length) {
                    g?.abbreviation?.split(",").map((abbr: string) => {
                        const cleanedAbbr = spaceCleaner(abbr);
                        processedChildren = createGlossary(cleanedAbbr, processedChildren);
                    });
                }
            }
        });

    const checkGlossaryVariants = (variants: string[], term: string): boolean => {
        let found = false;
        variants?.length &&
            variants.map((v) => {
                if (term?.toLowerCase() === spaceCleaner(v?.toLowerCase())) {
                    found = true;
                }
            });
        return found;
    };

    const transformGlossary: TransformFunction = (element) => {
        if (element) {
            const data =
                (query?.glossary?.nodes?.length &&
                    typeof element?.props?.children === "string" &&
                    query?.glossary?.nodes?.find(
                        (e: { term: string; abbreviation: string; specialties: { title: string }[] }) => {
                            return (
                                (e?.term?.toLowerCase() === element?.props?.children?.toLowerCase() ||
                                    checkGlossaryVariants(
                                        e?.abbreviation?.split(",") || [],
                                        element?.props?.children,
                                    )) &&
                                e?.specialties?.length &&
                                e?.specialties.find((s) => {
                                    return s?.title === siteSpecialty?.title;
                                })
                            );
                        },
                    )) ||
                query?.glossary?.nodes?.find((e) => {
                    return (
                        (e?.term?.toLowerCase() === element?.props?.children?.toLowerCase() ||
                            checkGlossaryVariants(e?.abbreviation?.split(",") || [], element?.props?.children)) &&
                        !e?.specialties?.length
                    );
                });

            return (
                <GlossaryLink {...data} showGlossary>
                    {element?.props?.children}
                </GlossaryLink>
            );
        }
    };

    const transformFootnotes: TransformFunction = (element) => {
        const footnoteIndex = element?.props?.children || "0";

        let footnote = null as Footnote;
        footnotes?.forEach(({ footnotes }: CMSFootnotes) => {
            const match = footnotes.find(({ index }: Footnote) => index === parseInt(footnoteIndex));
            if (match) {
                footnote = match;
            }
        });

        if (footnote) {
            return (
                <Tooltip
                    extraStyles={extraStyles}
                    text={
                        footnote.text ? (
                            <Typography>
                                <JWMHtml placement={placement} aria-hidden={true} showGlossary={false}>
                                    {footnote.text}
                                </JWMHtml>
                            </Typography>
                        ) : (
                            ""
                        )
                    }
                    onClick={(): void => scrollIntoView(`footnote-${footnote.index}`)}
                    linkText={t("Read more", { ns: "common" })}
                    data-test-id={`footnote-${footnote.index}-tooltip`}
                >
                    <button
                        href="#"
                        className={classnames(
                            style.footNote,
                            !ignoreFootNote ? `footnote-${footnote.index}-text` : null,
                        )}
                        data-test-id={`footnote-${footnote.index}-text`}
                        id={
                            !ignoreFootNote
                                ? element.props.className?.includes("existing")
                                    ? null
                                    : `footnote-${footnote.index}-text`
                                : null
                        }
                        onClick={(e): void => {
                            e.preventDefault();
                            scrollIntoView(`footnote-${footnote.index}`);
                            e.target?.blur();
                        }}
                        aria-describedby="footnotes-label"
                    >
                        <sup>[{footnote.index}]</sup>
                    </button>
                </Tooltip>
            );
        }
    };

    const transformLinks: TransformFunction = (element, color, children) => {
        let path: string;
        const currentPath = typeof window !== `undefined` ? window?.location.pathname : "";

        const external =
            element?.props?.href?.startsWith("mailto:") ||
            element?.props?.href?.startsWith("http") ||
            element?.props?.href?.startsWith("tel:");

        if (!element?.props?.href?.startsWith("#")) {
            path = `${element?.props?.href}`;
            if (!external) {
                // Relative links have a special treatment.
                if (!element?.props?.href?.startsWith("/")) {
                    const slugs = currentPath.split("/");
                    const withoutLocale = slugs.filter((e) => e !== locale).join("/");
                    // Remove the URL part after the last / and add our path.
                    path = withoutLocale.substring(0, withoutLocale.lastIndexOf("/") + 1) + path;
                }
            }
        } else {
            // Anchor links uses current path in the URL.
            path = currentPath;
        }

        const getName = (element: JSX.Element): string => {
            let name = "";
            if (typeof element?.props?.children === "string") {
                name = element?.props?.children;
            } else if (element?.props?.children?.[0]) {
                name = getName(element?.props?.children?.[0]);
            } else if (typeof element === "string" || element instanceof String) {
                name = element as unknown as string;
            } else if (element?.props?.href) {
                name = element?.props?.href;
            }
            return name;
        };

        return (
            <JWMLink
                name={getName(element)}
                external={external}
                fullWidth={false}
                anchor_id={element?.props?.href?.startsWith("#") ? element?.props?.href : ""}
                url={path}
                placement={placement}
                newWindow={element?.props?.target === "_blank"}
            >
                {cleanGlossaryLink(children)}
            </JWMLink>
        );
    };

    const transformImage: TransformFunction = (element) => {
        return (
            <img {...element.props} alt={element?.props?.alt || ""} style={{ maxWidth: "100%" }} loading={loading} />
        );
    };

    const transformCookieButton: TransformFunction = (element, color, children) => {
        return (
            <button
                {...element.props}
                onClick={(e) => {
                    e.preventDefault();
                    window?.OneTrust?.ToggleInfoDisplay();
                }}
            >
                {children}
            </button>
        );
    };

    const transformer: TransformFunction = (element, color, size, children) => {
        if (element?.props?.id === "ot-sdk-btn") {
            return transformCookieButton(element, color, children);
        }
        if (element?.type === "sup" && element?.props?.className?.startsWith("footnote")) {
            return transformFootnotes(element);
        }
        if (element?.type === "a") {
            return transformLinks(element, color, children);
        }

        if (element?.type === "glossary") {
            return transformGlossary(element);
        }

        if (element?.type === "img") {
            return transformImage(element);
        }
    };

    return (
        <Html onTransform={transformer} size={size} useLargeText={useLargeText} {...other}>
            {processedChildren}
        </Html>
    );
};

export default JWMHtml;
