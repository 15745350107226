import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { useEventListener } from "@jmc/utils/hooks/useEventListener";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import React, { useEffect, useRef } from "react";

import style from "./style.module.scss";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    name: string;
    fullHeight?: boolean;
}

export const NavigationImpression = (props: PropTypes): JSX.Element => {
    const { children, name, fullHeight = false } = props;

    const navigationContainer = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(navigationContainer);
    const { interacted } = useCookieInteraction();

    // Track the navigation impression.
    // By setting the doCollect option we are telling the analytics library
    // to fire the event directly instead of queuing and flushing it.
    // This prevents race conditions that potentially lead to double events.
    useEffect(() => {
        if (isVisible && interacted) {
            setTimeout(() => {
                analyticstracker().trackImpression(EventTypes.NAVIGATION_IMPRESSION);
            }, 1000);
        }
    }, [navigationContainer, isVisible, interacted, name]);

    // track clicks registering the id of the clicked element
    useEventListener(
        "click",
        (e: MouseEvent) => {
            // eslint-disable-next-line
            const path = (e as any)?.composedPath() || [];

            // keep the clicks within this navigation container
            for (let x = 0; x < path.length; x++) {
                const step = path[x];
                const container = step?.dataset?.trackingNavigationContainer;
                if (container && container === name) {
                    // if the first found container is this one then we continue
                    break;
                } else if (container && container !== name) {
                    // if the first found container is not this one then we exit
                    return;
                }
            }

            // look for the first id in  the click path to use as linkname
            let linkname: string = undefined;
            for (let x = 0; x < path.length; x++) {
                const step = path[x];

                // don't look outside of the navigation container
                if (step === navigationContainer.current) {
                    break;
                }
                // return the first found id
                if (step?.getAttribute("id") !== null) {
                    linkname = step.getAttribute("id");
                    break;
                }
                // return data linkname if a data-linkname attribute is present
                if (step?.getAttribute("data-linkname") !== null) {
                    linkname = step.getAttribute("data-linkname");
                    break;
                }
            }

            // Adding level if a data-level attribute is present
            const level = (e.target as Element)?.closest("li")?.getAttribute("data-level");
            if (level && linkname) linkname = `level${level}.${linkname}`;

            // adding the click type based on user clicks
            let clickType = "mouse";
            if (e?.clientX === 0 && e?.clientY === 0) clickType = "enter";

            // only if an id is found with the click path we track it
            if (linkname) {
                analyticstracker().trackInteraction(e, {
                    changeEvent: EventTypes.NAVIGATION_CLICK,
                    addData: { info: { linkname, click_type: clickType } },
                });
            }
        },
        navigationContainer,
    );

    return (
        <div
            role="none"
            data-tracking-event={EventTypes.NAVIGATION_IMPRESSION}
            data-tracking-info={JSON.stringify({ name })}
            data-tracking-navigation-container={name}
            ref={navigationContainer}
            className={fullHeight ? style.fullHeight : null}
        >
            {children}
        </div>
    );
};

export default NavigationImpression;
