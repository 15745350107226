const INDEX_SUFFIX = `${process.env.GATSBY_SEARCH_REGION}-${process.env.GATSBY_ALGOLIA_INDEX_ENVIRONMENT}`;

const INDEX_CONTENT = `Content-${INDEX_SUFFIX}`;
const INDEX_LOCATION = `Location-${INDEX_SUFFIX}`;
const INDEX_CONTENT_SUGGESTIONS = `Content-query-suggestions-${INDEX_SUFFIX}`;

const CONTENT_TYPES = {
    SECTION: "Section",
    RECIPE: "Recipe",
    AUDIO: "Audio",
    VIDEO: "Video",
    MATERIALS: "Materials",
    ATE_QUESTIONS: "ATE Questions",
};

module.exports = { INDEX_CONTENT, INDEX_LOCATION, INDEX_CONTENT_SUGGESTIONS, CONTENT_TYPES };
