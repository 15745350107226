import { Action } from "@redux/create-value-module";
import merge from "lodash/merge";

import { ValueStageConstants } from "./value-stages-enum";
/**
 * Create reducer for the value module
 */
export const createReducer =
    (constants: ValueStageConstants, initialState: object) =>
    (state: object = initialState, { type, payload }: Action): object => {
        if (type === constants.RESET) {
            return initialState;
        }
        if (type === constants.SET) {
            return payload;
        }
        if (type === constants.MERGE) {
            return merge({}, state, payload);
        }
        return state;
    };
