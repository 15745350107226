/* eslint-disable @typescript-eslint/no-use-before-define */
import useUserInfo from "@hooks/useUserInfo/useUserInfo";
import { ApplicationState } from "@redux/modules";
import { ValidAuthResults } from "@redux/modules/authValid";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";

import { logout } from "../../api/auth";
import { useSiteStartPage } from "../../contexts/SiteContext";

export interface PropTypes {
    timeout: number; // number of seconds after which to logout the user
}

declare global {
    interface Window {
        testLogout: (launch: boolean) => void;
    }
}

export const LogoutWhenIdle = ({ timeout }: PropTypes): JSX.Element => {
    const authSlice = useSelector((state: ApplicationState) => state.authValid.data as ValidAuthResults); //redux store using hooks
    const [idle, setIdle] = useState(false);
    const testEnv = process.env.GATSBY_ENVIRONMENT === "qa";
    const userInfo = useUserInfo();
    const logoutRedirectUri = encodeURI(useSiteStartPage());
    const isAuthenticated = userInfo.isAuthenticated();
    const loggedOutQueryString = `${logoutRedirectUri}?errorCode=InactiveLoggedOut`;

    useEffect(() => {
        //exposing timer globally
        window.testLogout = (launch: boolean) => {
            launch ? start() : pause();
        };
    }, []);

    useEffect(() => {
        if (authSlice && idle) {
            if (isAuthenticated) {
                isLeader() ? logout(loggedOutQueryString) : window.location.reload(); //if page is considered leader, trigger logout, other tabs simply refresh
            }
        }
    }, [idle]);

    const { isLeader, start, pause } = useIdleTimer({
        timeout: timeout * 1000,
        onIdle: () => {
            setIdle(true);
        },
        debounce: 500,
        startOnMount: !testEnv,
        startManually: testEnv,
        leaderElection: true,
        crossTab: {
            emitOnAllTabs: true,
            channelName: "idle-timer",
        },
    });

    return <></>;
};

export default LogoutWhenIdle;
