import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import classnames from "classnames";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useMegaMenuContext } from "../MegaMenuContext";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import { useScreenSize } from "@jmc/utils/hooks/useScreenSize";
import { default as stripSlash } from "@jmc/utils/utils/strip-slash";
import { useLocation } from "@reach/router";
import { keyHandler } from "@jmc/utils/utils/keyHandler";

import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { Icon, jnjArrowLeft, jnjChevronRight } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";

import style from "./style.module.scss";
import { CSSProperties } from "@material-ui/core/styles/withStyles.js";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

interface SubMenuProps {
    id?: string;
    title?: string;
    index?: string;
    link?: JSX.Element;
    mobileLink?: JSX.Element;
    level?: number;
    children: JSX.Element | JSX.Element[];
    megaMenuRef?: React.MutableRefObject<any>;
}

// Apply closing and opening delays to prevent flickering when moving between two submenus.
export const CLOSE_DELAY = 1;
export const OPEN_DELAY = 2;
// Apply a delay to the opening of the mega menu. The design team decided that 240ms is the magic number.
export const MEGAMENU_OPEN_DELAY = 240;

export const activePath = (
    refElement: MutableRefObject<HTMLInputElement>,
    activeMenuIndex: string,
    setActiveMenuIndex: React.Dispatch<React.SetStateAction<string>>,
    index: string,
): boolean => {
    const activeMenuLink = stripSlash.stripTrailing(
        encodeURI(refElement?.current?.querySelector("a")?.getAttribute("href")),
    );

    // Using window.location here because React useLocation does not get updated properly
    const location = typeof window !== "undefined" ? window?.location : undefined;
    const currentPath = stripSlash.stripTrailing(location?.pathname) || "";
    const fullCurrentPath = stripSlash.stripTrailing(`${currentPath}${location?.hash || ""}`);

    if (currentPath === activeMenuLink || fullCurrentPath === activeMenuLink) {
        setActiveMenuIndex(index);
        return true;
    }
    // first parent level
    if (activeMenuIndex && activeMenuIndex?.substring(0, activeMenuIndex?.lastIndexOf(".")) === index) {
        return true;
    }
    // final parent level
    if (activeMenuIndex && activeMenuIndex?.substring(0, activeMenuIndex?.indexOf(".")) === index) {
        return true;
    }
    return false;
};

export const SubMenu = (props: SubMenuProps): JSX.Element => {
    const { title, link, mobileLink, children, level, index, id, megaMenuRef } = props;
    const elementRef = useRef(null);
    const subMenuContents = useRef(null);
    const isVisible = useOnScreen(subMenuContents);
    const [height, setHeight] = React.useState(0);
    const [minHeight, setMinHeight] = React.useState("unset");
    const location = useLocation();
    const isMobile = useMediaQuery(BreakPoint.md);
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const timeOutID = useRef(null);

    const screenSize = useScreenSize();
    const [mobileTop, setMobileTop] = React.useState(0);
    const [mobileHeight, setMobileHeight] = React.useState(0);
    const { jnjFullBranded } = useJnjBranding();

    const {
        megaMenuOpen,
        interestingLinks,
        interestingLinksHeight,
        secondLevelHeight,
        setSecondLevelHeight,
        thirdLevelHeight,
        setThirdLevelHeight,
        closeHandler,
        setCloseHandler,
        backLiteral,
        activeMenuIndex,
        setActiveMenuIndex,
    } = useMegaMenuContext();

    const handleMouseEnter = (): void => {
        if (!isMobile) {
            if (level == 1) {
                // Close any other open menu
                closeHandler();

                // Set the close handler to this submenu
                setCloseHandler(() => () => {
                    setOpen(false);
                });
            }

            if (!megaMenuOpen) {
                // Set a delay to open the menu if the megamenu is not yet open
                timeOutID.current = setTimeout(() => {
                    setOpen(true);
                }, MEGAMENU_OPEN_DELAY);
            } else {
                setOpen(true);
            }
        }
    };

    const handleMouseLeave = (): void => {
        if (!isMobile) {
            if (open) {
                setOpen(false);
            }
            // If we hover out of the megamenu, clear the timer so it won't open
            clearTimeout(timeOutID.current);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLLIElement, Element>): void => {
        if (!isMobile) {
            // Check menu levels
            const origin = parseInt(event?.target?.closest("li")?.getAttribute("data-level")) || 0;
            const destination = parseInt(event.relatedTarget?.closest("li")?.getAttribute("data-level")) || 0;
            const isInterestingLinks = event.relatedTarget?.closest(`.${style.interestingLinksContainer}`);

            if (origin > destination && !isInterestingLinks) {
                setOpen(false);
                if (origin == 3) event.stopPropagation();
            } else if (origin == 1 && destination == 1) {
                setOpen(false);
            }
        }
    };

    const handleHashChanged = () => {
        if (open && !isMobile) {
            setOpen(false);
            setActiveMenuIndex("");
        }
    };

    useEffect(() => {
        setOpen(false);
        setActiveMenuIndex("");
    }, [location?.href]);

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChanged);

        return (): void => {
            window.removeEventListener("hashchange", handleHashChanged);
        };
    });

    useEffect(() => {
        const timeout = isVisible ? OPEN_DELAY : CLOSE_DELAY;
        setTimeout(() => {
            const elementHeight = subMenuContents?.current?.offsetHeight || 0;
            switch (level) {
                case 1:
                    isVisible ? setSecondLevelHeight(elementHeight) : setSecondLevelHeight(0);
                    break;
                case 2:
                    isVisible ? setThirdLevelHeight(elementHeight) : setThirdLevelHeight(0);
                    break;
                default:
                    break;
            }
            if (!isVisible && height != 0) setHeight(0);
        }, timeout);
    }, [isVisible]);

    useEffect(() => {
        const computedHeight = Math.max(secondLevelHeight, thirdLevelHeight, interestingLinksHeight);
        if (computedHeight !== height) setHeight(computedHeight);
    }, [secondLevelHeight, thirdLevelHeight, interestingLinksHeight]);

    const item = link ? (
        link
    ) : (
        <button tabIndex={0} className={style.title}>
            <Typography>{title}</Typography>
        </button>
    );

    const handleKeyPress = (event: React.KeyboardEvent<HTMLLIElement>): void => {
        const parent = (event?.target as Element)?.parentElement;

        if (event.code == "Enter" && !open) {
            // Open the menu and move focus to first item
            event.preventDefault();
            setOpen(true);

            setTimeout(() => {
                const origin = parent?.nextElementSibling;
                const link = origin?.querySelector("ul").querySelector("a, button");
                link?.focus();
            }, OPEN_DELAY);
        }
        if (event.code == "Escape" && open) {
            // Close the menu and select the parent item
            setOpen(false);

            setTimeout(() => {
                const link = parent?.closest("ul")?.closest("li")?.querySelector("a, button");
                link?.focus();
            }, CLOSE_DELAY);
        }
    };

    const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent) => {
        if (isMobile) {
            event.stopPropagation();
            event.preventDefault();
            setOpen(!open);
        }
    };

    useEffect(() => {
        setMinHeight(height > 0 ? `${height}px` : "unset");
    }, [height]);

    useEffect(() => {
        setIsActive(activePath(elementRef, activeMenuIndex, setActiveMenuIndex, index));
    });

    useEffect(() => {
        // Calculate position and height for mobile submenus
        if (isMobile) {
            const menuDiv = megaMenuRef?.current?.closest("[data-test-id='MegaMenu.Wrapper']")?.getBoundingClientRect();
            setMobileTop((menuDiv?.top | 0) + 1);
            setMobileHeight(screenSize.height - ((menuDiv?.top | 0) + 1));
        }
    }, [screenSize.height]);

    const styles = { minHeight: minHeight } as CSSProperties;
    if (isMobile && typeof window !== "undefined") {
        styles.top = mobileTop;
        styles.height = mobileHeight;
    }

    return (
        <li
            role="menuitem"
            ref={elementRef}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            onBlur={(event) => handleOnBlur(event)}
            onKeyDown={handleKeyPress}
            className={classnames(
                style[`submenu-level-${level}`],
                open ? style.open : null,
                isActive ? style[`active-item`] : null,
            )}
            data-level={level}
            data-test-id={id ? `SubMenu.Level${level}.${id}` : `SubMenu.Level${level}`}
        >
            <div
                className={style.item}
                onClick={handleOnClick}
                onTouchEnd={handleOnClick}
                data-test-id="SubMenu.Title"
                role="presentation"
            >
                {item}
                <Icon
                    className={style.chevron}
                    color="black"
                    icon={jnjFullBranded ? jnjChevronRight : mdiChevronRight}
                    type={jnjFullBranded ? "jnj" : "mdi"}
                    verticalAlignMiddle
                    size={jnjFullBranded ? "small" : "medium"}
                    data-test-id="SubMenu.RightArrow"
                    id={
                        id
                            ? `${id}.chevron-right`
                            : typeof title === "string"
                            ? `${title}.chevron-right`
                            : "chevron-right"
                    }
                />
            </div>

            <div
                className={style[`wrapper-level-${level}`]}
                ref={subMenuContents}
                style={styles}
                data-test-id={minHeight !== "unset" ? "SubMenu.Wrapper.Content" : null}
            >
                <div className={classnames(style.content)} data-test-id="SubMenu.Content">
                    <div className={style.mobileContext}>
                        <button
                            className={style.back}
                            onClick={(event) => handleOnClick(event)}
                            onKeyDown={(e: KeyboardEvent): void => keyHandler(e.key, () => handleOnClick(e))}
                        >
                            {jnjFullBranded ? (
                                <>
                                    <Icon
                                        className={style.chevron}
                                        color="primary"
                                        icon={jnjArrowLeft}
                                        type="jnj"
                                        verticalAlignMiddle
                                        size="small"
                                    />
                                    <Typography color="primary-general" size="l">
                                        {backLiteral}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Icon
                                        className={style.chevron}
                                        color="black"
                                        icon={mdiChevronLeft}
                                        verticalAlignMiddle
                                        size="medium"
                                    />
                                    <Typography>{backLiteral}</Typography>
                                </>
                            )}
                        </button>
                        {mobileLink || link ? (
                            <div className={style.title} data-test-id="SubMenu.Content.Title">
                                {mobileLink || link}
                            </div>
                        ) : (
                            <Typography
                                font="title"
                                size={jnjFullBranded ? "2xl" : "4xl"}
                                weight={jnjFullBranded ? "400" : "500"}
                                data-test-id="SubMenu.Content.Title"
                            >
                                {title}
                            </Typography>
                        )}
                    </div>
                    <ul role="menu" data-test-id="SubMenu.Content.Links">
                        {children}
                    </ul>
                    {level === 1 && (
                        <div
                            className={style.interestingLinksContainer}
                            data-test-id="SubMenu.Content.InterestingLinks"
                        >
                            {interestingLinks}
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};
