import { join } from "@jmc/utils/utils/path";

export const getSearchUrl = (
    locale: string,
    search_url: string,
    query: string,
    siteFilter = null as string,
    encode = true,
): string => {
    const searchUrl = `/${join(locale, search_url)}`;
    let queryString = `?q=${encode ? encodeURIComponent(query) : query}`;
    if (siteFilter) {
        queryString += `&site=${encode ? encodeURIComponent(siteFilter) : siteFilter}`;
    }

    return `${searchUrl}${queryString}`;
};

export default getSearchUrl;
