import { Html } from "@jmc/solid-design-system/src/components/molecules/Html/Html";
import { Modal } from "@jmc/solid-design-system/src/components/molecules/Modal/Modal";
import React, { useEffect, useState } from "react";
import { capitalize } from "@jmc/utils/utils/capitalize";
import { NotificationImpression } from "@jmc/core/src/components/NotificationImpression";

import style from "./style.module.scss";

export interface GlossaryProps {
    abbreviation?: string;
    description?: string;
    modal_title?: string;
    term?: string;
    children?: string;
    showGlossary?: boolean;
}

export const GlossaryLink = (props: GlossaryProps): JSX.Element => {
    const { abbreviation, children, description, modal_title, term, showGlossary }: GlossaryProps = props;

    const [openModal, setOpenModal] = useState(false);
    const [action, setAction] = useState(null);

    useEffect(() => {
        // Only add listener if modal is actually open
        openModal && document.addEventListener("keydown", escapeKeyDown);

        return (): void => {
            // Unbind the event listener on clean up
            document.removeEventListener("keydown", escapeKeyDown);
        };
    }, [openModal]);

    // Close modal if Escape is pressed
    const escapeKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Escape") {
            setAction("Close");
            setTimeout(() => {
                setOpenModal(false);
                setAction(null);
            }, 10);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            setOpenModal(true);
        }
    };

    return showGlossary ? (
        <>
            {abbreviation && children?.toLowerCase() === abbreviation?.toLowerCase() ? (
                <abbr title={term} className={style.link}>
                    <span
                        tabIndex={0}
                        onClick={() => setOpenModal(true)}
                        onKeyDown={(e) => handleKeyDown && handleKeyDown(e as unknown as KeyboardEvent)}
                        role="button"
                    >
                        {children}
                    </span>
                </abbr>
            ) : (
                <span
                    className={style.link}
                    tabIndex={0}
                    onClick={() => setOpenModal(true)}
                    onKeyDown={(e) => handleKeyDown && handleKeyDown(e as unknown as KeyboardEvent)}
                    role="button"
                >
                    {children}
                </span>
            )}
            <Modal
                blurBackground
                position={"center"}
                onClose={() => {
                    setAction("Close");
                    setTimeout(() => {
                        setOpenModal(false);
                        setAction(null);
                    }, 10);
                }}
                open={openModal}
                spacing="large"
                enableRemoveScroll={false}
            >
                <NotificationImpression
                    name={modal_title || (typeof term === "string" && capitalize(term))}
                    type="glossary pop-up"
                    trigger="user-action"
                    action={action}
                >
                    <Modal.Title title={modal_title || (typeof term === "string" && capitalize(term))} />
                    <Modal.Content>
                        <div className={style.description}>
                            <Html>{description}</Html>
                        </div>
                    </Modal.Content>
                </NotificationImpression>
            </Modal>
        </>
    ) : (
        <>{children}</>
    );
};

export default GlossaryLink;
