import { CMSLinkGroupProps } from "@jwm/types/CMSLinkGroupProps";

export const getLink = (
    linkGroup: CMSLinkGroupProps,
    title?: string,
    main_category?: string,
    parent?: string,
): {
    anchor_id: string;
    main_category: string;
    parent_category: string;
    external: boolean;
    url: string;
    url_prefix: string;
    title: string;
    id: string;
} => {
    const external = Boolean(linkGroup?.external?.href || linkGroup?.external_link);
    const url =
        linkGroup?.external_link ||
        linkGroup?.external?.href ||
        linkGroup?.internal?.[0]?.url ||
        linkGroup?.internal_page?.[0]?.url ||
        "";

    const linkTitle =
        title || linkGroup?.external?.title || linkGroup?.internal?.[0]?.title || linkGroup?.internal?.[0]?.page_title;

    return {
        anchor_id: linkGroup?.anchor_id || "",
        main_category,
        parent_category: parent,
        external,
        url:
            !external || /^(?:f|ht)tps?:\/\//.test(url) || url?.startsWith("mailto") || url?.startsWith("tel")
                ? url
                : "https://" + url,
        url_prefix:
            linkGroup?.internal_page?.[0]?.site_reference?.[0]?.url_prefix ||
            linkGroup?.internal?.[0]?.site_reference?.[0]?.url_prefix ||
            "",
        title: linkTitle,
        id: linkTitle,
    };
};
