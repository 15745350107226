import { AutocompleteState, BaseItem } from "@algolia/autocomplete-core";
import { AutoCompleteType, RECENT_SEARCHES_PLUGIN_NAME } from "@jmc/core/src/components/AutoComplete/types";
import { buildAutocomplete, buildPlugins } from "@jmc/core/src/components/AutoComplete/utils";
import { Icon, jnjArrowLeft } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { mdiArrowLeft } from "@mdi/js";
import algoliasearch from "algoliasearch/lite";
import React, { useCallback, useMemo, useState } from "react";
import { SearchBox } from "@jmc/core/src/components/SearchBox";
import { useLocale } from "@jmc/core/src/hooks/useLocale";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);

interface PropTypes {
    navigate: (query: string) => void;
    index: string;
    suggestionsIndex: string;
    contentType?: string;
    setSearchOpen: (value: boolean) => void;
    searchBoxRef: React.MutableRefObject<any>;
    containerRef: React.MutableRefObject<any>;
    enableVoiceSearch?: boolean;
    enablePreviousSearches?: boolean;
    enablePopularSearches?: boolean;
    enableAutocompleteSearch?: boolean;
    siteFilter?: string;
}

export const SearchBar = (props: PropTypes): JSX.Element => {
    const [autocompleteState, setAutocompleteState] = useState({} as AutocompleteState<BaseItem>);
    const [searchState, setSearchState] = useState({ query: "" });
    const {
        navigate,
        index,
        suggestionsIndex,
        setSearchOpen,
        searchBoxRef,
        containerRef,
        enableVoiceSearch = false,
        enablePreviousSearches = true,
        enablePopularSearches = true,
        enableAutocompleteSearch = true,
    } = props;
    const locale = useLocale();
    const [voiceButton, setVoiceButton] = useState(null);
    const { t } = useTranslation();
    const { jnjFullBranded } = useJnjBranding();

    const onSubmit = useCallback(({ state }: { state: { query: string } }) => {
        setSearchOpen(false);
        navigate(state?.query);
    }, []);

    const onReset = useCallback(() => {
        setSearchState((searchState) => ({
            ...searchState,
            query: "",
        }));
    }, []);

    const onSelect = (query: string): void => {
        const recentSearchesPlugin = plugins?.find((plugin) => plugin?.name === RECENT_SEARCHES_PLUGIN_NAME);
        recentSearchesPlugin.data.addItem({ id: query, label: query });
        setSearchOpen(false);
        onSelectCallback(query);
    };

    const onSelectCallback = useCallback((value: string): void => {
        autocomplete.getFormProps({ inputElement: searchBoxRef.current }).onReset(new Event("click"));
        setSearchOpen(false);
        navigate(value);
    }, []);

    const voiceSearchButton = (children: JSX.Element): void => {
        setVoiceButton(children);
    };

    const plugins = useMemo(
        () =>
            buildPlugins(
                searchClient,
                suggestionsIndex,
                onSelectCallback,
                enableVoiceSearch ? voiceSearchButton : null,
                locale,
                enablePreviousSearches,
                enablePopularSearches,
                enableAutocompleteSearch,
            ),
        [],
    );

    const autocomplete = useMemo(() => {
        return buildAutocomplete(setAutocompleteState, plugins, null);
    }, []);

    return (
        <div className={style.searchBox}>
            <div className={style.header}>
                <button
                    id="CloseSearch"
                    tabIndex={0}
                    className={style.closeButtonMobile}
                    onClick={() => setSearchOpen(false)}
                    title={t("Close", { ns: "common" })}
                >
                    <Icon
                        color={jnjFullBranded ? "inherit" : "primary"}
                        icon={jnjFullBranded ? jnjArrowLeft : mdiArrowLeft}
                        type={jnjFullBranded ? "jnj" : "mdi"}
                        size={jnjFullBranded ? "medium" : "large"}
                        verticalAlignMiddle
                    />
                </button>

                <div className={jnjFullBranded ? style.container : "container"} style={{ width: "100%" }}>
                    <SearchBox
                        initialState={{
                            query: searchState?.query,
                        }}
                        openOnFocus={true}
                        onSubmit={onSubmit}
                        onReset={onReset}
                        onSelect={onSelect}
                        plugins={plugins}
                        indexName={index}
                        inputRef={searchBoxRef}
                        containerRef={containerRef}
                        autocomplete={autocomplete as unknown as AutoCompleteType}
                        autocompleteState={autocompleteState}
                        navigate={navigate}
                        voiceButton={voiceButton}
                        enablePreviousSearches={enablePreviousSearches}
                        enablePopularSearches={enablePopularSearches}
                        enableAutocomplete={enableAutocompleteSearch}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
