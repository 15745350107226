import classnames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";

import style from "./style.module.scss";
import { Icon, jnjSearch } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { mdiMagnify } from "@mdi/js";
import { SearchBar } from "@jmc/core/src/components/SearchBar";
import { useLocation } from "@reach/router";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { Hamburger } from "@jmc/solid-design-system/src/components/atoms/Hamburger/Hamburger";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "@jmc/solid-design-system/src/hooks/useClickOutside";
import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "../../types/EventTypes";
import throttle from "lodash/throttle";
import { RemoveScroll } from "react-remove-scroll";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

export const HEADER_COLLAPSED = "headerCollapsed";
export const HEADER_WITH_TOPBAR = "withTopBar";

interface PropTypes {
    title?: string;
    topBarContent?: JSX.Element; // Link with Platform logo to go back when inside of a minisite
    logo: JSX.Element; // Main logo to display: platform logo or minisite logo
    languageSelector: JSX.Element;
    userMenu: JSX.Element;
    menu: JSX.Element;
    cart?: JSX.Element;
    hideOnScroll?: boolean;
    navigate: (query: string) => void;
    enableSearch?: boolean;
    index: string;
    suggestionsIndex: string;
    contentType: string;
    closeSearchOnDesktop?: boolean;
    HeaderWrapper?: React.ElementType; // Allows us to wrap header content with elements (Navigation Impressions, etc.) without breaking sticky positioning
    useFullWidth?: boolean;
    enableVoiceSearch?: boolean;
    enablePreviousSearches?: boolean;
    enablePopularSearches?: boolean;
    enableAutocompleteSearch?: boolean;
    medical?: boolean;
    siteFilter?: string;
}

/**
 * Header component.
 */
export const Header = (props: PropTypes): JSX.Element => {
    const {
        logo,
        menu,
        topBarContent,
        languageSelector,
        userMenu,
        navigate,
        enableSearch = true,
        index,
        suggestionsIndex,
        contentType,
        hideOnScroll = true,
        closeSearchOnDesktop = false,
        cart,
        HeaderWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => <>{children}</>,
        useFullWidth = false,
        enableVoiceSearch = false,
        medical = false,
        enablePopularSearches = true,
        enablePreviousSearches = true,
        enableAutocompleteSearch = true,
        siteFilter = null,
    } = props;

    const { jnjFullBranded } = useJnjBranding();
    const [withTopBar, setWithTopBar] = useState(!!topBarContent);
    const [searchOpen, setSearchOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [preventCollapse, setPreventCollapse] = useState(false);
    const [preventExpand, setPreventExpand] = useState(false);
    const searchBoxRef = useRef(null);
    const searchContainer = useRef(null);
    const location = useLocation();
    const isMobile = useMediaQuery(BreakPoint.md);
    const isBrowser = typeof document !== "undefined";
    const { t } = useTranslation();
    let lastScrollPosition = isBrowser ? document.documentElement.scrollTop || window.pageYOffset : 0;

    const eventTracking = (linkName: string): void => {
        analyticstracker().trackEvent({
            event: EventTypes.NAVIGATION_CLICK,
            info: {
                name: "header_navigation",
                linkname: linkName,
            },
        });
    };

    useEffect(() => {
        if (closeSearchOnDesktop && isPageLoaded) {
            if (searchOpen) {
                eventTracking("sitesearch_magnifier");
            } else {
                eventTracking("sitesearch_close");
            }
        }
        setIsPageLoaded(true);
    }, [searchOpen]);

    useEffect(() => {
        setSearchOpen(false);
        setWithTopBar(!!topBarContent);
        setMobileNavOpen(false);
    }, [location]);

    const onHashChange = (): void => {
        setMobileNavOpen(false);
    };

    // Listen for hashchange events to close the mobile menu.
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("hashchange", onHashChange);

            return (): void => window.removeEventListener("hashchange", onHashChange);
        }
    });

    const handleSearchClick = (): void => {
        setSearchOpen(true);
        if (searchBoxRef?.current) {
            setTimeout(() => {
                searchBoxRef.current.focus();
            }, 100);
        }
    };

    const handleMobileNavClick = (): void => {
        setMobileNavOpen(!mobileNavOpen);
    };

    useClickOutside(searchContainer, () => {
        closeSearchOnDesktop && !isMobile && setSearchOpen(false);
    });

    const handleKeyPress = (event: React.KeyboardEvent<HTMLAnchorElement>, open: boolean): void => {
        if (event.code == "Enter" || event.code == "Space") {
            if (open) {
                handleSearchClick();
            } else {
                setSearchOpen(false);
            }
        }
    };

    //using custom logic instead of use scroll for more versatility
    useEffect(() => {
        const scrolling = throttle(() => {
            if (hideOnScroll && !(isMobile && mobileNavOpen)) {
                const st = window.scrollY || document.documentElement.scrollTop;
                if (preventCollapse) {
                    if (!preventExpand) setCollapsed(false);
                } else if (st > lastScrollPosition) {
                    // downscroll code
                    if (!collapsed) setCollapsed(true);
                } else if (st < lastScrollPosition) {
                    // upscroll code
                    if (collapsed && !preventExpand) setCollapsed(false);
                } // else was horizontal scroll
                lastScrollPosition = st <= 0 ? 0 : st;
            }
        }, 30);
        const scrollStop = () => {
            if (preventCollapse) setPreventCollapse(false);
            if (preventExpand) setPreventExpand(false);
        };
        const anchorscrolling = () => {
            setPreventCollapse(true);
        };

        const anchorCollapsedScrolling = () => {
            setPreventExpand(true);
        };

        // Add event listeners for scrolling and anchor events
        document.addEventListener("scroll", scrolling);
        document.addEventListener("anchorScrolled", anchorscrolling);
        document.addEventListener("scrollend", scrollStop);
        document.addEventListener("anchorCollapsedScroll", anchorCollapsedScrolling);

        // Clean up event listeners when component unmounts
        return () => {
            document.removeEventListener("scroll", scrolling);
            document.removeEventListener("anchorScrolled", anchorscrolling);
            document.removeEventListener("scrollend", scrollStop);
            document.removeEventListener("anchorCollapsedScroll", anchorCollapsedScrolling);
        };
    });

    // Please don't remove this!
    // It's a dirty fix for the mega menu not opening correctly on some pages the first time you hover over it.
    // On some pages there's a circular dependency in the logo's props, so this needs to be 'broken' with the
    // function we provide to JSON.stringify.
    // By stringifying the logo's props, useMemo doesn't get called unneccessarily and
    // the mega menu renders properly every time, hooray!
    let stringifiedLogoProps = null;
    if (logo !== null) {
        stringifiedLogoProps = JSON.stringify(logo.props, function (key, value) {
            if (key == "_owner" && value !== null) {
                return value.key;
            } else {
                return value;
            }
        });
    }

    const memoizedContent = useMemo(() => {
        return (
            <HeaderWrapper>
                {topBarContent && (
                    <div className={style.topBar} data-test-id="Header.TopBar">
                        {topBarContent}
                    </div>
                )}
                <div className={classnames(style.headerContainer, useFullWidth ? style.fullWidth : null)}>
                    <div className={classnames(style.main, closeSearchOnDesktop ? style.closeSearchOnDesktop : null)}>
                        {menu ? (
                            <Hamburger
                                className={style.toggleMenu}
                                open={mobileNavOpen}
                                onClick={() => handleMobileNavClick()}
                                aria-label={
                                    mobileNavOpen
                                        ? t("Close main menu", { ns: "navigation" })
                                        : t("Open main menu", { ns: "navigation" })
                                }
                                id="Main.Menu.Toggle"
                                data-test-id={mobileNavOpen ? "HamburgerIcon.Close" : "HamburgerIcon.Open"}
                                openText={t("open", { ns: "navigation" })}
                                closeText={t("close", { ns: "navigation" })}
                            />
                        ) : null}

                        <div className={style.logo} data-test-id="Header.Logo">
                            {logo}
                        </div>
                        {enableSearch && (
                            <div
                                className={classnames(style.search, searchOpen ? style.open : null)}
                                data-test-id="Header.Search"
                            >
                                <SearchBar
                                    navigate={navigate}
                                    index={index}
                                    suggestionsIndex={suggestionsIndex}
                                    contentType={contentType}
                                    setSearchOpen={setSearchOpen}
                                    searchBoxRef={searchBoxRef}
                                    containerRef={searchContainer}
                                    enableVoiceSearch={enableVoiceSearch}
                                    enablePreviousSearches={enablePreviousSearches}
                                    enablePopularSearches={enablePopularSearches}
                                    enableAutocompleteSearch={enableAutocompleteSearch}
                                    siteFilter={siteFilter}
                                />
                            </div>
                        )}
                        <div className={style.tools} data-test-id="Header.Tools">
                            {enableSearch && (
                                <button
                                    className={classnames(style.searchToggle, searchOpen ? style.open : null)}
                                    onClick={() => handleSearchClick()}
                                    onKeyDown={(e) => handleKeyPress(e, true)}
                                    data-test-id="Header.OpenSearch"
                                    title={t("Open search", { ns: "navigation" })}
                                    aria-label={t("Open search", { ns: "navigation" })}
                                >
                                    <Icon
                                        color={jnjFullBranded ? "inherit" : "primary"}
                                        icon={jnjFullBranded ? jnjSearch : mdiMagnify}
                                        size="medium"
                                        verticalAlignMiddle={true}
                                    />
                                </button>
                            )}
                            {cart}
                            <div className={style.language}>{languageSelector}</div>
                            <div className={style.user}>{userMenu}</div>
                        </div>
                    </div>
                    {menu ? (
                        //removing unwanted scroll in mobile navigation
                        <RemoveScroll enabled={mobileNavOpen} data-test-id="MegaMenu.Wrapper">
                            <div
                                className={classnames(style.navigation, mobileNavOpen ? style.open : null)}
                                data-test-id={mobileNavOpen ? "Header.Menu.Open" : "Header.Menu.Closed"}
                            >
                                {menu}
                            </div>
                        </RemoveScroll>
                    ) : null}
                </div>
            </HeaderWrapper>
        );
    }, [location.pathname, location.hash, searchOpen, mobileNavOpen, stringifiedLogoProps, enableSearch, cart]);

    return (
        <div
            id="main-header"
            className={classnames(
                style.header,
                medical ? style.medical_ribbon : null,
                collapsed ? style.collapsed : null,
                collapsed ? HEADER_COLLAPSED : null,
                withTopBar ? style.withTopBar : null,
                withTopBar ? HEADER_WITH_TOPBAR : null,
                useFullWidth ? style.fullWidth : null,
            )}
            data-test-id="MainHeader"
        >
            {memoizedContent}
        </div>
    );
};
