import { CMSFootnotes } from "@jwm/types/CMSFootnotes";

import createValueModule from "../create-value-module";

const { reducer, actions } = createValueModule("footnotes", [] as CMSFootnotes[]);

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
