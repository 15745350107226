import { default as path } from "@jmc/utils/utils/path";

/**
 * Builds the full URL to a service endpoint.
 *
 * @param endpoint URL of the endpoint, begining with a /
 * @param queryString Query string to be appended to the request.
 * @returns String with the full URL of the service endpoint.
 */
export const getEndpointUrl = (endpoint = "", queryString = ""): string => {
    return path.join(process.env.GATSBY_API_BASE_URL, process.env.GATSBY_API_VERSION || "", endpoint, queryString);
};
