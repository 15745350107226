import { default as stripSlash } from "@jmc/utils/utils/strip-slash";

/**
 * Builds a page URL.
 */
export const getInternalLink = (locale = "", urlPrefix = "", url = "", params = null as string): string => {
    const urlStripped = stripSlash.strip(url);
    const prefixStripped = stripSlash.strip(urlPrefix);
    let result = urlStripped;

    // Handle parameters.
    const paramStr = (): string => {
        if (!params) {
            return "";
        }
        return `?${new URLSearchParams(params).toString()}`;
    };

    if (!urlPrefix && !url?.startsWith("/")) {
        // These are relative URLs, added in a text block, so we don't need to do anything.
        result = url || "";
    } else {
        result = prefixStripped
            ? `${stripSlash.strip(locale)}/${prefixStripped}/${urlStripped}${paramStr()}`
            : urlStripped
            ? `${stripSlash.strip(locale)}/${urlStripped}${paramStr()}`
            : url
            ? `${stripSlash.strip(locale)}`
            : "";
    }

    if (url?.startsWith("/") && !result?.startsWith("/")) {
        result = "/" + result;
    }

    // Make sure there are no double slashes in the URL.
    result = result.replace(/([^:]\/)\/+/g, "$1");

    return result;
};

export default getInternalLink;
