import { ColorWrapper } from "@components/ColorWrapper/ColorWrapper";
import { EloquaTracking } from "@components/EloquaTracking/EloquaTracking";
import { ErrorMessage } from "@components/ErrorMessage/ErrorMessage";
import { Footer } from "@components/Footer/Footer";
import { IssueCollector } from "@components/IssueCollector/IssueCollector";
import { JWMButton } from "@components/JWMButton/JWMButton";
import { JWMHeader } from "@components/JWMHeader/JWMHeader";
import { JWMJNJBar } from "@components/JWMJNJBar/JWMJNJBar";
import { NavigationImpression } from "@components/NavigationImpression/NavigationImpression";
import { PageImpression } from "@components/PageImpression/PageImpression";
import { SEO } from "@components/SEO/SEO";
import { LangSwitchProvider } from "@contexts/LangSwitchContext";
import { SiteProvider } from "@contexts/SiteContext";
import useUserInfo from "@hooks/useUserInfo/useUserInfo";
import analyticstracker from "@jmc/analyticstracker";
import { BackToTop } from "@jmc/core/src/components/BackToTop";
import { DayjsWrapper } from "@jmc/core/src/components/DayjsWrapper/DayjsWrapper";
import { FontWrapper } from "@jmc/core/src/components/FontWrapper/index";
import { ScrollImpression } from "@jmc/core/src/components/ScrollImpression/index";
import { ScrollWidth } from "@jmc/core/src/components/ScrollWidth/index";
import { WebVitalsTracking } from "@jmc/core/src/components/Tracking";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { ImageQueryResponse } from "@jmc/core/src/types/ImageQueryReponse";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import useIsClient from "@jmc/utils/hooks/useIsClient";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { CMSHeroProps } from "@jwm/types/CMSHeroProps";
import { CMSLanguage } from "@jwm/types/CMSLanguage";
import { CMSPage } from "@jwm/types/CMSPage";
import { CMSService } from "@jwm/types/CMSService";
import { PageContext } from "@jwm/types/PageContext";
import { mapSEOProps } from "@utils/cms-mapper/mapSEOProps";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import yn from "yn";

import { AuthProviders } from "../../api/authProviders";
import { AccessLevel } from "../../types/AccessLevel";
import ShowErrorMessage from "./ShowErrorMessage/ShowErrorMessage";
import style from "./style.module.scss";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    pageContext: PageContext;
    data: {
        page: CMSPage;
        languages: { edges: [CMSLanguage] };
        service?: CMSService;
    };
    fullLayout?: boolean; // Set to false by the 404 page to avoid printing header / footer
    do_not_index_page?: boolean;
}

const ShowActionMessage = (): JSX.Element => {
    const { t } = useTranslation();
    const isClient = useIsClient();
    const { jnjFullBranded } = useJnjBranding();

    if (isClient) {
        const queryParams = new URLSearchParams(window?.location?.search);
        const action = queryParams?.get("action");

        if (action) {
            const actions = {
                RegistrationFormSuccess: t(
                    "login:Account isn't valid because of pending email validation or user identification",
                ),
            };
            const message = actions[action];
            return <ErrorMessage message={message} signIn={jnjFullBranded} />;
        }
    }
    return null;
};

export const Layout = ({
    children,
    pageContext,
    data,
    fullLayout = true,
    do_not_index_page = false,
}: PropTypes): JSX.Element => {
    // TODO: Deprecate page background color after rebranding. Only white background will be used.
    const pageBackground = data?.page?.display_options?.background_color?.toLowerCase() || "grey";

    const siteReference = pageContext?.page?.site_reference?.[0];
    const footer = siteReference?.branding?.footer?.[0];
    const specialty = siteReference?.related_to?.[0];

    const userInfo = useUserInfo();
    const locale = useLocale();
    const isMobile = useMediaQuery(BreakPoint.md);

    let path = "";
    if (typeof window !== `undefined`) {
        path = window?.location.pathname;
    }

    const { t } = useTranslation();
    const [enableSkipNavigation, setEnableSkipNavigation] = useState(false);
    const [withJNJBar, setWithJNJBar] = useState(false);

    const tabFunction = (event: { key: string }): void => {
        if (event.key === "Tab") {
            setEnableSkipNavigation(true);
        }
    };

    const eventTracking = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        let clickType = "mouse";
        if (e?.clientX === 0 && e?.clientY === 0) clickType = "enter";
        const info = {
            name: t("skip to content", { ns: "common" }),
            category: "primary contained",
            placement: `page:${pageContext?.page?.title} | Header`,
            targeturl: window?.location?.href,
            targettype: "internal",
            click_type: clickType,
        };
        analyticstracker().trackEvent({
            event: EventTypes.BUTTON_CLICK,
            info: info,
        });
    };

    useEffect(() => {
        document.addEventListener("keydown", tabFunction);

        return (): void => {
            document.removeEventListener("keydown", tabFunction);
        };
    }, [path]);

    const metadata = mapSEOProps(data?.page, pageContext, do_not_index_page);

    let imrNumber = footer?.imr_number;
    let footerLastUpdated = footer?.updated_at;

    if (siteReference?.imr_number) {
        imrNumber = siteReference?.imr_number;
        footerLastUpdated = siteReference?.updated_at;
    }
    if (data?.page?.nodes?.[0]?.imr_number) {
        imrNumber = data?.page?.nodes?.[0]?.imr_number;
        footerLastUpdated = data?.page?.nodes?.[0]?.updated_at;
    }
    if (data?.service?.imr_number) {
        imrNumber = data?.service?.imr_number;
        footerLastUpdated = data?.service?.updated_at;
    }
    if (data?.page?.imr_number) {
        imrNumber = data?.page?.imr_number;
        footerLastUpdated = data?.page?.updated_at;
    }

    const needAccessCode = data?.page?.access_control?.access_level === AccessLevel.AccessCode;
    let hasAccessCode = false;
    if (
        path !== "" &&
        needAccessCode &&
        userInfo.hasProvider(AuthProviders.accessCode) &&
        userInfo.isValidAccessCodeForPage(path?.replace("/" + locale, ""))
    ) {
        hasAccessCode = true;
    }

    const renderPage = (needAccessCode && hasAccessCode) || !needAccessCode ? true : false;

    const siteRef = renderPage
        ? siteReference
        : {
              ...siteReference,
              branding: {
                  ...siteReference?.branding,
                  header: [] as CMSHeroProps[],
                  logo: null as ImageQueryResponse,
              },
              header: [] as CMSHeroProps[],
              custom_colors: {
                  primary_color: "",
                  secondary_color: "",
                  accent_color: "",
                  use_custom_colors: false,
              },
          };

    return (
        <FontWrapper locale={pageContext.locale}>
            <SEO {...metadata} />
            <SiteProvider siteReference={siteRef}>
                <LangSwitchProvider languages={data?.languages?.edges}>
                    <Typography.tooltipContext.Provider
                        value={t(
                            "common:This medicine is subject to additional monitoring. This will allow quick identification of new safety information.",
                        )}
                    >
                        <PageImpression context={pageContext} specialty={specialty}>
                            <div
                                className={classNames(
                                    style.body,
                                    style[`body__background_${pageBackground}`],
                                    withJNJBar ? style.withJNJBar : null,
                                )}
                            >
                                <ScrollWidth>
                                    <ScrollImpression>
                                        {fullLayout ? (
                                            <>
                                                {enableSkipNavigation && (
                                                    <div className={style.skipContent}>
                                                        <a
                                                            href="#bodycontent"
                                                            onClick={(e) => !isMobile && eventTracking(e)}
                                                        >
                                                            <JWMButton
                                                                buttonProps={{ variant: "contained", color: "primary" }}
                                                                text={t("skip to content", { ns: "common" })}
                                                                placement={`page:${pageContext?.page?.title} | Header`}
                                                                url={window?.location?.pathname}
                                                                disableLink={true}
                                                                rounded={false}
                                                            />
                                                        </a>
                                                    </div>
                                                )}
                                                <JWMHeader
                                                    title={data?.page?.page_title || data?.page?.display_title}
                                                    site={siteRef}
                                                    showSearch={pageContext.showSearch}
                                                />
                                                <JWMJNJBar withJNJBar={withJNJBar} setWithJNJBar={setWithJNJBar} />
                                                <ColorWrapper reference={siteRef}>
                                                    {children}

                                                    <ShowActionMessage />
                                                    <ShowErrorMessage />
                                                    <BackToTop />
                                                    <NavigationImpression name="footer_navigation">
                                                        <DayjsWrapper>
                                                            <Footer
                                                                disable_last_updated_date={
                                                                    footer?.disable_last_updated_date
                                                                }
                                                                disclaimer={footer?.disclaimer}
                                                                imr_number={imrNumber}
                                                                legal_entity_name={footer?.legal_entity_name}
                                                                legal_links={footer?.legal_links}
                                                                logo={footer?.logo}
                                                                social_media={footer?.social_media}
                                                                updatedAt={footerLastUpdated}
                                                            />
                                                        </DayjsWrapper>
                                                    </NavigationImpression>
                                                </ColorWrapper>
                                            </>
                                        ) : (
                                            <>
                                                {children}
                                                <JWMJNJBar withJNJBar={setWithJNJBar} />
                                            </>
                                        )}
                                    </ScrollImpression>
                                </ScrollWidth>
                            </div>
                        </PageImpression>
                        <EloquaTracking />
                    </Typography.tooltipContext.Provider>
                </LangSwitchProvider>
            </SiteProvider>
            {yn(process.env.GATSBY_ISSUE_COLLECTOR_ENABLED) && <IssueCollector />}
            <WebVitalsTracking />
        </FontWrapper>
    );
};

export default Layout;
