import { Expert } from "templates/services/ask-the-experts/types";

import createValueModule from "../create-value-module";

export interface AskTheExperts {
    started: boolean;
    expert: Expert | null;
    specialty: string | null;
    progress: number | null;
    preview: boolean | null;
}

const { reducer, actions } = createValueModule("askTheExperts", {
    specialty: null,
    progress: null,
    expert: null,
    started: null,
    preview: null,
} as AskTheExperts);

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
