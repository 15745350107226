import { LoginTypes } from "@jwm/types/LoginTypes";

import { AccessLevel } from "../../types/AccessLevel";
import createApiModule from "../create-api-module";
/**
 * Obtain the currently authenticated users auth state
 * Spec: https://d46r7oibliv63.cloudfront.net/staging/index.html#operation/get-auth-valid
 */
const { reducer, actions } = createApiModule("authValid", () => `/auth/valid`);
export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export interface ValidAuth {
    userId?: string;
    level: AccessLevel;
    loginType: LoginTypes;
    services?: string[];
    urls?: string[];
    provider: string;
    email: string;
}

export interface ValidAuthResults {
    results: ValidAuth[];
}

export interface AuthData {
    data?: ValidAuthResults;
    loading?: boolean;
    error?: object;
    params?: object;
}
