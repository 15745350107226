import React, { Component } from "react";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "./react-awesome-lightbox.css";

interface ImageProps {
    url: string;
    title?: string;
}

interface PropTypes {
    defaultSelectedImage?: number;
    onClose: () => void;
    children: JSX.Element | JSX.Element[];
}

/*
 * Do not delete this!
 * This component is used to pass the properties of the image(s).
 * We then transform the image(s) in the LightBox component.
 */
export class Image extends Component<ImageProps> {
    render() {
        return <></>;
    }
}

export class LightBox extends Component<PropTypes> {
    static Image = Image;

    render() {
        const { children, defaultSelectedImage, onClose } = this.props;

        if (React.Children.count(children) === 1) {
            return (
                <Lightbox
                    data-test-id="LightBox"
                    image={(children as React.ReactElement)?.props?.url}
                    title={(children as React.ReactElement)?.props?.title}
                    onClose={onClose}
                />
            );
        } else {
            const images = React.Children.map(children, (child: React.ReactElement) => {
                return {
                    url: child.props.url,
                    title: child.props.title,
                };
            });

            return (
                <Lightbox data-test-id="LightBox" startIndex={defaultSelectedImage} images={images} onClose={onClose} />
            );
        }
    }
}
