/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iteratee = require("lodash/iteratee");
const isObject = require("lodash/isObject");
const isFunction = require("lodash/isFunction");
const tail = require("lodash/tail");
const includes = require("lodash/includes");
const isArray = require("lodash/isArray");
const flatten = require("lodash/flatten");
const transform = require("lodash/transform");
const sanitizeHtml = require("sanitize-html");

/**
 *
 * This method is used to optionally remove ct or c if the option is set false in ot and o.
 * @param {*} collection
 * @param {boolean} found
 */
function filterOption(collection, found) {
    if (!found) {
        Object.keys(collection).map((key) => {
            if (collection && collection) {
                if (collection.ot === false) {
                    if (collection.ct) {
                        delete collection["ct"];
                        delete collection["ot"];
                    }
                    if (collection[key] && collection[key].ct) {
                        delete collection[key]["ct"];
                        delete collection["ot"];
                    }
                }
                if (collection.o === false) {
                    if (collection.c) {
                        delete collection["c"];
                        delete collection["o"];
                    }
                    if (collection[key] && collection[key].c) {
                        delete collection[key]["c"];
                        delete collection["o"];
                    }
                }
            }
            if (collection && collection[key]) {
                if (collection[key].ot === false) {
                    if (collection[key].ct) {
                        delete collection[key]["ct"];
                        delete collection[key]["ot"];
                    }
                    if (collection.ct) {
                        delete collection["ct"];
                        delete collection[key]["ot"];
                    }
                }
                if (collection[key].o === false) {
                    if (collection[key].c) {
                        delete collection[key]["c"];
                        delete collection[key]["o"];
                    }
                    if (collection.c) {
                        delete collection["c"];
                        delete collection[key]["o"];
                    }
                }
            }
        });
    }
    if (found) {
        Object.keys(collection).map((key) => {
            if (collection && collection[key]) {
                if (collection[key].ot === false) {
                    Object.keys(collection).map((c) => {
                        if (collection && collection[c] && collection[c].ct) {
                            delete collection[c]["ct"];
                        }
                    });
                    delete collection[key]["ot"];
                }
                if (collection[key].o === false) {
                    Object.keys(collection).map((c) => {
                        if (collection && collection[c] && collection[c].c) {
                            delete collection[c]["c"];
                        }
                    });
                    delete collection[key]["o"];
                }
            }
        });
    }
    return collection;
}

/**
 *
 * @param {*} collection
 */
function filterOptional(collection) {
    if (collection && Object.keys(collection).length) {
        // Filters out disabled ct/c for siblings, chidren and parents
        collection = filterOption(collection, false);
        // Filters out remaining disabled ct/c cousins
        collection = filterOption(collection, true);
    }
    return collection;
}

/**
 * This method recursively transforms a collection into an accumulator in which all the values of the collection matching the predicate are collected into an array.
 * Used to recursively collect all keys with a certain name into 1 array.
 *
 * Example:
 * const collection = {
 *      root: {
 *          level1: {
 *              key: "value1"
 *          }
 *          key: "value2"
 *          anotherKey: 3
 *      }
 *      key: "value3"
 *      anotherKey: 4
 * };
 *
 * collectDeep(collection, {}, false, 'key')
 * // => {key: ["value1", "value2", "value3"], anotherKey: [3, 4]}
 *
 * Setting the sanitize flag to true will cleanup all HTML markup from the collected values.
 *
 * @param {*} collection
 * @param {*} accumulator
 * @param {*} sanitize
 * @param {*} predicate
 * @param {*} thisArg
 * @returns
 */
function collectDeep(collection, accumulator, sanitize, predicate, thisArg) {
    if (isFunction(predicate)) {
        predicate = iteratee(predicate, thisArg);
    } else {
        // eslint-disable-next-line prefer-rest-params
        const keys = flatten(tail(tail(tail(arguments))));
        //console.log("looking for ", keys, " in ", JSON.stringify(collection, null, "  "));
        predicate = function (val, key) {
            return includes(keys, key);
        };
    }

    const result = transform(
        collection,
        function (acc, val, key) {
            const include = predicate(val, key);

            if (!include && (isObject(val) || isArray(val))) {
                val = filterOptional(val);
                val = collectDeep(val, acc, sanitize, predicate);
                // check optional here val = filterOptional(val)
            }
            if (include) {
                const value = sanitize ? sanitizeHtml(val, { allowedTags: [], allowedAttributes: {} }) : val;
                // if (sanitize) {
                //     console.log("COLLECTED:", val);
                //     console.log("PARSED", value);
                // }
                (acc[key] = acc[key] || []).push(value);
                //console.log("FOUND SOMETHING in collection: ", collection, key, val, acc);
            }
        },
        accumulator,
    );
    return result;
}

module.exports = { collectDeep };
