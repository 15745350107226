import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { CMSMinisite, CMSSiteColors } from "@jwm/types/CMSMinisite";
import React, { createContext, useContext } from "react";

interface PropTypes {
    siteReference: CMSMinisite;
    children: JSX.Element;
}

export const siteContext = createContext(null);

/**
 * Stores the site of the current page in a contexts.
 * Fields available for the site object here are defined in page-queries > PageQuery.js
 */
export const SiteProvider = ({ children, siteReference }: PropTypes): JSX.Element => {
    return <siteContext.Provider value={siteReference}>{children}</siteContext.Provider>;
};

export const useSiteColors = (): CMSSiteColors => {
    const site = useContext(siteContext);
    return site?.custom_colors;
};

export const useUrlPrefix = (): string => {
    const site = useContext(siteContext);

    return site?.url_prefix;
};

export const useSiteUid = (): string => {
    const site = useContext(siteContext);

    return site?.uid;
};

export const useSiteReference = (): CMSMinisite => {
    const site = useContext(siteContext);

    return site;
};

export const useSiteStartPage = (): string => {
    const site = useContext(siteContext);
    const locale = useLocale();

    const url_prefix = site?.url_prefix || "";
    const startPage = site?.startpage?.[0]?.url || "";
    const origin = typeof window !== "undefined" && window?.location?.origin;

    // Ensure any extraneous slashes are removed.
    let unprocessedPath = `${locale}/${url_prefix}${startPage}`;
    unprocessedPath = unprocessedPath.replace(/\/+/g, "/");
    const path = unprocessedPath.replace(/\/$/, "");

    return `${origin}/${path}`;
};

export default { SiteProvider, siteContext, useSiteColors, useSiteReference, useSiteStartPage, useUrlPrefix };
