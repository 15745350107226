import createValueModule from "../create-value-module";

export interface Panes {
    tabPaneId: string;
    accordionPaneId: string;
}

const { reducer, actions } = createValueModule("panes", {
    tabPaneId: null,
    accordionPaneId: null,
});

export default reducer;
export const clear = actions.clear;
export const set = actions.set;
export const update = actions.update;
