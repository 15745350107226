import sanitizeHtml from "sanitize-html";
import { IngredientsType, MethodType, RecipeProps } from "templates/services/recipe/types/RecipeProps";

const sanitizeMultiple = (data: string[]): string[] => {
    const list: string[] = [];

    data?.length &&
        data?.map((item: string) => {
            let clean = "";

            try {
                clean = sanitizeHtml(item, {
                    allowedTags: [],
                });
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn("Error sanitizing HTML", item, e.toString());
            }
            list.push(clean);
        });

    return list;
};

const ingredientList = (ingredients: IngredientsType[]): string[] => {
    const list: string[] = [];
    ingredients?.length &&
        ingredients?.map((item) => {
            if (item?.ingredient?.name) list.push(item?.ingredient?.name);
        });
    return list;
};

const instructions = (method: MethodType[]): string[] => {
    let list: string[] = [];
    method?.length &&
        method?.map((item) => {
            if (item?.method_step?.instructions) list = [...list, ...sanitizeMultiple(item?.method_step?.instructions)];
        });
    return list;
};

/**
 * Returns recipe schema ldJson
 *
 */
const recipeSchema = (recipe: RecipeProps): string => {
    const { title, image, recipe_details, general, nutritional_facts, ingredients, method, publish_details } = recipe;
    const { difficulty, dietary_wishes, course } = general;
    const { cook_time, serves, total_time } = recipe_details;
    const { calories } = nutritional_facts;
    const keywords = [difficulty, ...dietary_wishes];

    const ldJson = {
        "@context": "https://schema.org/",
        "@type": "Recipe",
        name: title,
        image: image?.url,
        datePublished: publish_details?.time,
        cookTime: cook_time ? `PT${cook_time}M` : "",
        prepTime: total_time ? `PT${total_time}M` : "",
        keywords: `${keywords?.length ? keywords?.join(", ") : ""}`,
        nutrition: {
            "@type": "NutritionInformation",
            calories,
        },
        recipeCategory: `${course?.length ? course?.join(", ") : ""}`,
        recipeIngredient: ingredientList(ingredients),
        recipeInstructions: instructions(method),
        recipeYield: serves,
    };

    return JSON.stringify(ldJson).replace("&quot;", `"`);
};

export default recipeSchema;
