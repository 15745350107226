import analyticstracker from "@jmc/analyticstracker";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { useEventListener } from "@jmc/utils/hooks/useEventListener";
import { default as fileHelper } from "@jmc/utils/utils/file-helper";
import React, { useRef } from "react";
import style from "./style.module.scss";

export interface PropTypes {
    children: JSX.Element | JSX.Element[];
    name: string;
}

export const FileDownloadImpression: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { children, name } = props;

    const fileContainer = useRef<HTMLDivElement>(null);

    useEventListener(
        "click",
        (e: Event) => {
            analyticstracker().trackInteraction(e);
        },
        fileContainer,
    );

    return (
        <div
            className={style.fileDownloadImpression}
            role="none"
            data-tracking-event={EventTypes.FILE_DOWNLOAD}
            data-tracking-info={JSON.stringify({ name, type: fileHelper?.getFileType(name) })}
            ref={fileContainer}
        >
            {children}
        </div>
    );
};

export default FileDownloadImpression;
