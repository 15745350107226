import { AgeGroupOptions } from "@components/ContactRequest/OptionData/ContactRequestPreferenceOptions";

import createApiModule from "../create-api-module";

export interface ContactRequests {
    data: {
        received: ContactsData;
        sent: ContactsData;
    };
    error: {
        message: string;
        errorCode: string;
        awsRequestId: string;
    };
    loading: boolean;
}

export interface ContactsData {
    counts: {
        accepted: number;
        declined: number;
        pending: number;
    };
    accepted: [ContactRequest];
    declined: [ContactRequest];
    pending: [ContactRequest];
}

export interface ContactRequest {
    fromId: string;
    toId: string;
    sfUserId?: string;
    email: string;
    status: string;
    username: string;
    message?: string;
    avatar?: string;
    disease?: string;
    role?: string;
    gender?: string;
    ageGroup?: AgeGroupOptions;
}

/**
 * Gets the list of contacts for the user.
 *
 * @param userId Salesforce Id of the user
 */
const { reducer, actions } = createApiModule("contactRequest", ({ userId }) => {
    return `/contact-requests/${userId}`;
});

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
