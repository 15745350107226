import { CMSLanguage } from "@jwm/types/CMSLanguage";
import React, { createContext, useEffect, useState } from "react";

interface PropTypes {
    children: JSX.Element;
    languages: CMSLanguage[];
}

export const langSwitchContext = createContext([]);

export const LangSwitchProvider = ({ children, languages = [] }: PropTypes): JSX.Element => {
    const [langList, setLangList] = useState(languages);

    useEffect(() => {
        if (languages?.length > 0) setLangList(languages);
    }, [languages]);

    return <langSwitchContext.Provider value={[langList, setLangList]}>{children}</langSwitchContext.Provider>;
};

export default { LangSwitchProvider, langSwitchContext };
