import useService, { ServiceTypes } from "@hooks/useService/useService";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { locale2lang } from "@jmc/utils/utils/locale2lang";
import { default as stripSlash } from "@jmc/utils/utils/strip-slash";
import { getSearchUrl } from "@utils/getSearchUrl";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

interface TwitterOg {
    title?: string;
    description?: string;
    image?: { url?: string };
    path?: string;
}
interface PropTypes {
    title: string;
    metaTitle?: string;
    siteSuffix?: string;
    siteSpecialty?: string;
    description?: string;
    canonical?: string;
    hreflangs?: string[];
    clonesHreflangs?: { url: string; locale: string }[];
    twitter?: TwitterOg;
    openGraph?: TwitterOg;
    doNotIndexPage?: boolean;
    siteFallbackImage?: string;
    ldJson?: string;
    searchBox?: boolean;
    faqs?: { "@type": string; acceptedAnswer: { "@type": string; text: string }; name: string }[];
}

export interface DefaultTwitterImage {
    gatsbyImageData: GatsbyImageProps;
    url: string;
    publish_details: { locale: string };
}

export const SEO = ({
    title,
    metaTitle,
    siteSuffix,
    siteSpecialty = "",
    description,
    canonical,
    hreflangs,
    clonesHreflangs,
    twitter,
    openGraph,
    doNotIndexPage,
    siteFallbackImage,
    ldJson,
    searchBox = false,
    faqs,
}: PropTypes): JSX.Element => {
    const locale = useLocale();
    const searchService = useService(ServiceTypes.SEARCH);
    const { t } = useTranslation();

    const { query }: { query: { nodes: [DefaultTwitterImage] } } = useStaticQuery(graphql`
        {
            query: allContentstackAssets(filter: { url: { regex: "/(seo-fallback-image.png)$/" } }) {
                nodes {
                    url
                    gatsbyImageData
                    publish_details {
                        locale
                    }
                }
            }
        }
    `);

    const fallbackSEOImage = query?.nodes
        .filter((item: DefaultTwitterImage) => {
            return item?.publish_details?.locale == locale ? true : false;
        })
        .pop()?.url;

    const twitterImage = twitter?.image?.url || siteFallbackImage || fallbackSEOImage;
    const ogImage = openGraph?.image?.url || siteFallbackImage || fallbackSEOImage;

    return (
        <Helmet
            htmlAttributes={{
                lang: locale2lang(useLocale()),
            }}
        >
            <meta charSet="utf-8" />
            {description && <meta name="description" content={description} />}
            {doNotIndexPage && <meta name="robots" content="noindex" />}
            {process.env.GATSBY_GOOGLE_SEARCH_CONSOLE_VERIFICATION_TAG && (
                <meta
                    name="google-site-verification"
                    content={process.env.GATSBY_GOOGLE_SEARCH_CONSOLE_VERIFICATION_TAG}
                />
            )}
            {<link rel="canonical" href={canonical || ""} />}
            {hreflangs?.length > 0 &&
                hreflangs.map((u, index) => {
                    try {
                        const url = new URL(u);
                        const linkLocale = url.pathname.split("/")[1];
                        return <link rel="alternate" hrefLang={linkLocale} href={u} key={`hreflang-${index}`} />;
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                })}
            {clonesHreflangs?.length > 0 &&
                clonesHreflangs.map((c, index) => {
                    return <link rel="alternate" hrefLang={c?.locale} href={c?.url} key={`cloneHreflang-${index}`} />;
                })}
            <title>{(metaTitle || title) + (siteSuffix ? " | " + siteSuffix : "")}</title>
            <meta property="og:title" content={(openGraph?.title || title)?.substring(0, 96)} />
            <meta
                property="og:description"
                content={(openGraph?.description || description || "")?.substring(0, 297)}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={openGraph?.path || ""} />
            {ogImage && <meta property="og:image" content={ogImage} />}
            <meta name="twitter:title" content={(twitter?.title || title)?.substring(0, 70)} />
            <meta name="twitter:description" content={(twitter?.description || description || "")?.substring(0, 200)} />
            <meta name="twitter:card" content="summary_large_image" />
            {twitterImage && <meta name="twitter:image" content={twitterImage} />}
            {ldJson && <script type="application/ld+json">{ldJson || ""}</script>}
            {searchBox && (
                <script type="application/ld+json">
                    {`{
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": "${stripSlash.stripTrailing(process.env.GATSBY_APP_DOMAIN)}/${stripSlash.strip(locale)}/",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": "${stripSlash.stripTrailing(process.env.GATSBY_APP_DOMAIN)}${getSearchUrl(
                        locale,
                        searchService?.url,
                        "{search_term_string}",
                        null, // site title parameter, not needed here
                        false,
                    )}"
                        },
                        "query-input": "required name=search_term_string"
                    }
                }`}
                </script>
            )}
            {faqs?.length && (
                <script type="application/ld+json">
                    {`{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "specialty": "${siteSpecialty}",
                    "mainEntity": ${JSON.stringify(faqs)}
    }`}
                </script>
            )}
            {!doNotIndexPage && (
                <script type="application/ld+json">
                    {`{
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "author": {
                        "@type": "Organization",
                        "@id": "https://www.janssen.com/",
                        "name": "Janssen Pharmaceuticals",
                        "legalName": "${t("Janssen Pharmaceutica NV", { ns: "common" })}",
                        "sameAs": "https://www.janssen.com, https://www.janssenmedicalcloud.com, https://www.janssenwithme.com, https://en.wikipedia.org/wiki/Janssen_Pharmaceuticals, https://twitter.com/JanssenGlobal, https://www.linkedin.com/company/janssen-pharmaceutical-companies-of-johnson-and-johnson",
                        "image": "https://www.janssen.com/sites/all/themes/janssen/logo.png"
                    }
                }`}
                </script>
            )}
        </Helmet>
    );
};
export default SEO;
