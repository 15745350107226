/**
 * Scrolls to the position of an element with a given ID minding the header.
 * @param elementId id of the anchor
 * @param lookForClassName if the flag is enabled, look for a class rather than an id
 */

export const scrollIntoView = (elementId: string, lookForClassName = false): void => {
    const mainHeader = document.getElementById("main-header");
    const offset = mainHeader?.offsetHeight || 0;
    const padding = 20;
    const initialScroll = window.scrollY;
    const element = lookForClassName ? document.querySelector(`.${elementId}`) : document.getElementById(elementId);

    if (element) {
        element.scrollIntoView();

        const scrolledY = window.scrollY;
        if (scrolledY) {
            const setCorrection = (): number => {
                return mainHeader?.children.length > 1 && initialScroll > scrolledY
                    ? (mainHeader?.firstChild as HTMLElement)?.offsetHeight
                    : 0;
            };

            window.scroll(0, scrolledY - (offset + padding + setCorrection()));
        }

        element.focus();
    }
};

export default scrollIntoView;
