import { ProfileData } from "@redux/modules/profile";

import createApiModule from "../create-api-module";
export interface ContactRequestProfileData extends ProfileData {
    email: string;
    topicsOfInterest: string[];
    personalInterests: {
        sport: string[];
        leisure: string[];
        personality: string[];
        foodAndDrink: string[];
        animals: string[];
        appearance: string[];
        moviesAndSeries: string[];
        music: string[];
    };
    contactRequestProfileStatus: "" | "active" | "inactive";
}

export interface ContactRequestProfile {
    data: ContactRequestProfileData;
    error: {
        message: string;
        errorCode: string;
        awsRequestId: string;
    };
    loading: boolean;
}

const { reducer, actions } = createApiModule("contactRequestProfile", () => {
    return `/contact-requests/profile`;
});

export default reducer;
export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;
