import { AccountControls } from "@components/AccountControls/AccountControls";
import ColorWrapper from "@components/ColorWrapper/ColorWrapper";
import { LangSwitcher } from "@components/LangSwitcher/LangSwitcher";
import { NavigationImpression } from "@components/NavigationImpression/NavigationImpression";
import useMainNavigation from "@hooks/useMainNavigation/useMainNavigation";
import useService, { ServiceTypes } from "@hooks/useService/useService";
import { Header } from "@jmc/core/src/components/Header/Header";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import MegaMenu from "@jmc/solid-design-system/src/components/molecules/MegaMenu/MegaMenu";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { CMSMinisite } from "@jwm/types/CMSMinisite";
import { navigate } from "@reach/router";
import { getSearchUrl } from "@utils/getSearchUrl";
import React from "react";
import { useTranslation } from "react-i18next";

import { CONTENT_TYPES, INDEX_CONTENT, INDEX_CONTENT_SUGGESTIONS } from "../../search/algolia-indexes";
import { buildInterestingLinks, buildLogo, buildNavigationMenu, buildSiteMenu, buildTopBar } from "./JWMHeader.helpers";

interface PropTypes {
    title?: string;
    site: CMSMinisite;
    showSearch?: boolean; // The search icon / box is not shown in the search results page.
}

export const JWMHeader = (props: PropTypes): JSX.Element => {
    const { title, site, showSearch = true } = props;
    const siteTitle = site?.title?.replace(/ /g, "_").toLowerCase();
    const siteNavigation = `site_${siteTitle}_navigation`;
    const siteFilter = site?.url_prefix !== "/" ? site?.site_title : null;
    const searchService = useService(ServiceTypes.SEARCH);
    const configuration = useMainNavigation();
    const locale = useLocale();
    const { t } = useTranslation();
    const { jnjFullBranded } = useJnjBranding();

    const languageSelector = <LangSwitcher />;
    const userMenu = !!configuration?.show_login_controls && <AccountControls />;
    const interestingLinks = buildInterestingLinks(
        jnjFullBranded,
        t("Interesting links", { ns: "navigation" }),
        configuration?.interesting_links || [],
    );
    const topBarContent = buildTopBar(site, title, t("Visit Janssen With Me", { ns: "navigation" }), jnjFullBranded);
    const logo = buildLogo(configuration, site, title);

    const jwmNavigate = (query: string): void => {
        // AHAY-5424: Replacing the gatsby navigate function with the one from reach/router
        // The gatsby one causes parameters to be re-encoded when the user reloads the page, causing errors.
        // This might not work with yarn start, you have to manually reload the page, but works with yarn build.
        const url = getSearchUrl(locale, searchService?.url, query, siteFilter);
        navigate(url);
    };

    const buildMenu = (): JSX.Element => {
        let mainMenu = buildSiteMenu(site?.site_menu);
        if (!mainMenu && !site?.standalone) {
            mainMenu = buildNavigationMenu(jnjFullBranded, configuration?.navigation_menu);
        }

        return (
            <ColorWrapper reference={site}>
                <NavigationImpression
                    fullHeight={true}
                    name={site?.site_menu?.length ? siteNavigation : "default_navigation"}
                >
                    <MegaMenu
                        languageSelector={languageSelector}
                        interestingLinks={interestingLinks}
                        backLiteral={t("Back", { ns: "navigation" })}
                        scrollLeftLiteral={t("Scroll left", { ns: "navigation" })}
                        scrollRightLiteral={t("Scroll right", { ns: "navigation" })}
                        locale={locale}
                    >
                        {mainMenu}
                    </MegaMenu>
                </NavigationImpression>
            </ColorWrapper>
        );
    };

    // Without the wrapper, the hamburger icon animation does not work
    const headerWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
        return <>{children}</>;
    };

    return (
        <Header
            topBarContent={topBarContent}
            logo={logo}
            menu={buildMenu()}
            languageSelector={languageSelector}
            userMenu={userMenu}
            navigate={jwmNavigate}
            enableSearch={(searchService?.isEnabled || false) && showSearch && !site?.standalone}
            index={INDEX_CONTENT}
            suggestionsIndex={INDEX_CONTENT_SUGGESTIONS}
            contentType={CONTENT_TYPES.SECTION}
            closeSearchOnDesktop={jnjFullBranded ? false : true}
            enableVoiceSearch={searchService?.voiceEnabled || false}
            siteFilter={siteFilter}
            HeaderWrapper={headerWrapper}
        />
    );
};
