import { AxiosInstance, Method } from "axios";
import { Dispatch } from "redux";

import { ApiStageConstants, LOWER_CASE_API_STAGES } from "./api-stages-enum";
import { createReducer } from "./create-api-reducer";
import { createLoadAction, ParamsTypes } from "./create-load-action";
import { createConstants } from "./utils";

export interface State {
    loading?: boolean;
    error?: object;
    data?: object;
    params?: object;
}

export interface Action {
    type: string;
    payload?: object;
}

export interface Options {
    reducer?: (state: State, action: Action) => [State, boolean];
    initialState?: State;
    method?: Method;
    clearOnFetch?: boolean;
    clearOnError?: boolean;
    debounce?: boolean;
}

const DEFAULT_OPTIONS: Options = {
    reducer: null,
    initialState: {},
    method: "GET",
    clearOnFetch: false,
    clearOnError: false,
    // TODO: (AHAY-3442) Implement debounce
    debounce: false,
};

export interface ActionsType {
    load: (
        params?: ParamsTypes,
        force?: boolean,
        merging?: boolean,
    ) => (dispatch: Dispatch, getState: () => { [x: string]: State }, client: AxiosInstance) => Promise<string>;
    refetch: unknown;
    clear: unknown;
}

/**
 * Create an api module
 */
export default (
    namespace: string,
    getPath: (pathParams?: { [x: string]: object }) => string,
    options: Options = {},
): { constants: Record<string, unknown>; reducer: Record<string, unknown>; actions: ActionsType } => {
    options = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const constants = createConstants<ApiStageConstants>(LOWER_CASE_API_STAGES, "api", namespace);
    const load = createLoadAction(namespace, getPath, constants, options);

    // Should be called actionCreators but called actions for simplicity
    const actions = {
        clear: (): Action => ({
            type: constants.RESET,
        }),
        load,
        refetch: (): ((
            dispatch: Dispatch,
            getState: () => { [p: string]: State },
            client: AxiosInstance,
        ) => Promise<string>) => load(undefined, true),
    };

    return {
        constants,
        reducer: createReducer(constants, options),
        actions,
    };
};
