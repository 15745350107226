import { createReducer } from "./create-value-reducer";
import { createConstants } from "./utils";
import { LOWER_CASE_VALUE_STAGES, ValueStageConstants } from "./value-stages-enum";

export interface Action {
    type: string;
    payload?: object;
}

/**
 * Create value module
 */
export default (namespace: string, initialState: object): object => {
    const constants = createConstants<ValueStageConstants>(LOWER_CASE_VALUE_STAGES, "value", namespace);

    return {
        constants,
        reducer: createReducer(constants, initialState),
        actions: {
            clear: (): Action => ({
                type: constants.RESET,
            }),
            set: (payload: object): Action => ({
                type: constants.SET,
                payload,
            }),
            update: (payload: object): Action => ({
                type: constants.MERGE,
                payload,
            }),
        },
    };
};
