import { createLocale } from "@jmc/utils/utils/createLocale";
import axios from "axios";
import { log } from "loglevel";
import yn from "yn";

import { AuthProviders } from "./authProviders";
import { getEndpointUrl } from "./util";

/**
 * Sets the localhost header when the proxy is enabled.
 */
const getDefaultHeaders = (): object => {
    const defaultHeaders = {
        "Content-Type": "application/json",
        "X-Country": process.env.GATSBY_COUNTRY_CODE,
        "X-ApiVersion": process.env.GATSBY_API_VERSION || "api",
    };
    if (!yn(process.env.GATSBY_PROXY_ENABLED)) {
        return defaultHeaders;
    }

    return {
        "x-localhost": process.env.GATSBY_APP_DOMAIN.replace(/\/$/, ""),
        ...defaultHeaders,
    };
};

const getClientIdFromEnv = (countryCode: string = null): string => {
    const clientIds = JSON.parse(process.env.GATSBY_JANRAIN_CLIENT_IDS);
    return countryCode && clientIds[countryCode] ? clientIds[countryCode] : Object.values(clientIds)[0];
};

export const getRedirectUrl = (): string => {
    return (window.location.origin + window.location.pathname + window.location.search).replace(/\/$/, "");
};

/**
 *
 * Starts the registration flow, by returning the registrationUrl.  Specify the redirect URL
 * to the location that the user should be sent to after a successful registration.
 *
 * > this flow doesn't authenticate the user after registration, so a login is still required!
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/post-auth-register-init
 */

export const register = async (countryCode: string = null, url: string = null): Promise<string> => {
    const entryId = getClientIdFromEnv(countryCode);
    const allLanguages = process.env.GATSBY_LOCALES?.split(",");

    return axios
        .post(
            getEndpointUrl("/auth/register/init"),
            {
                redirectUrl: url || getRedirectUrl(),
                provider: AuthProviders.patient,
                entryId,
                country: countryCode, //this we store when creating a new profile
                locale: createLocale(location.pathname, 1, allLanguages),
            },
            {
                headers: {
                    ...getDefaultHeaders(),
                },
            },
        )
        .then((response) => {
            if (url) {
                return response?.data?.registrationUrl;
            } else {
                window.location.href = response?.data?.registrationUrl;
            }
        })
        .catch((error) => {
            log(error?.response);
        });
};

/**
 * Initializes the login flow and passes the current location to redirect back to after login.
 * During the redirect, secure cookies will be set to hold the access_token and refresh_token
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/post-auth-init
 */
export const login = (countryCode: string = null): Promise<void> => {
    const entryId = getClientIdFromEnv(countryCode);
    const allLanguages = process.env.GATSBY_LOCALES?.split(",");
    return axios
        .post(
            getEndpointUrl("/auth/init"),
            {
                redirectUrl: getRedirectUrl(),
                provider: AuthProviders.patient,
                entryId,
                country: countryCode, //this we store when creating a new profile
                locale: createLocale(location.pathname, 1, allLanguages),
            },
            {
                headers: {
                    ...getDefaultHeaders(),
                },
            },
        )
        .then((response) => (window.location.href = response?.data?.authorizationUrl))
        .catch((error) => {
            log(error?.response);
        });
};

/**
 * Logout and redirect to homepage.
 * TODO: (AHAY-3435) Find a better way, (add parameter) to determine the target page.
 * Spec: https://d46r7oibliv63.cloudfront.net/dev/v0/index.html#operation/post-auth-logout
 */
export const logout = (redirectUrl = `${window.location.origin}/`): Promise<void> =>
    axios
        .post(
            getEndpointUrl("/auth/logout"),
            {
                redirectUrl: redirectUrl,
                provider: AuthProviders.patient,
            },

            {
                headers: {
                    ...getDefaultHeaders(),
                },
            },
        )
        .then((response) => (window.location.href = response?.data?.logoutUrl));

/**
 * Navigate the user to the edit profile API endpoint.
 */
export const editProfile = (): void => {
    window.location.href = getEndpointUrl("/page/profile", "?redirectUrl=" + process.env.GATSBY_API_BASE_URL);
};

export default { login, logout, editProfile };
