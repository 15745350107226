import { SnackBar } from "@jmc/solid-design-system/src/components/atoms/SnackBar/SnackBar";
import { useClickOutside } from "@jmc/solid-design-system/src/hooks/useClickOutside";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { mdiAlertCircleOutline, mdiCheck } from "@mdi/js";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { login } from "../../api/auth";
import style from "./style.module.scss";

interface PropTypes {
    message: string;
    closeAfterSec?: number;
    onClose?: () => void;
    signIn?: boolean;
    type?: "error" | "success";
}

export const ErrorMessage = ({
    message,
    closeAfterSec = 15,
    type = "error",
    onClose,
    signIn,
}: PropTypes): JSX.Element => {
    const [open, setOpen] = useState(true);
    const [action, setAction] = useState(null);
    const { jnjFullBranded } = useJnjBranding();
    const { t } = useTranslation();

    const timer = useRef(null);

    const containerRef = useRef(null);

    useEffect(() => {
        if (closeAfterSec > 0) {
            timer.current = setTimeout(() => {
                setOpen(false);
                setAction("disappeared over time");
                onClose && onClose();
            }, closeAfterSec * 1000);
        }
    }, []);

    useEffect(() => {
        if (action) {
            clearTimeout(timer.current);
        }
    }, [action]);

    const userClosed = (): void => {
        if (!jnjFullBranded) {
            setOpen(false);
            setAction("user closed");
            onClose && onClose();
        }
    };

    useClickOutside(containerRef, userClosed);

    return (
        <Fragment>
            {open && (
                <div
                    className={style.error}
                    ref={containerRef}
                    onClick={(e) => {
                        const isSame = containerRef?.current === e?.target;
                        if (isSame) {
                            userClosed();
                        }
                    }}
                    role="presentation"
                >
                    <SnackBar
                        onClose={userClosed}
                        onSignIn={signIn ? () => login() : null}
                        color={jnjFullBranded ? "dark" : type}
                        signinLabel={jnjFullBranded ? t("Sign in", { ns: "login" }) : null}
                    >
                        <SnackBar.Icon
                            icon={type === "success" ? mdiCheck : mdiAlertCircleOutline}
                            data-test-id="SnackBar.Icon"
                            color={jnjFullBranded ? "white" : null}
                        />
                        <SnackBar.Text text={message} data-test-id="SnackBar.Text" />
                    </SnackBar>
                </div>
            )}
        </Fragment>
    );
};

export default ErrorMessage;
