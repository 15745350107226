import React, { useState, useEffect } from "react";
import { useWindowSize } from "react-use";
interface Props {
    children: JSX.Element | JSX.Element[];
}

const ScrollWidth = (props: Props): JSX.Element => {
    const { children } = props;
    const { width } = useWindowSize();
    // Check if document is defined (so if in the browser or in node.js).
    const isBrowser = typeof document !== "undefined";
    const initialWidth = width === Infinity ? 0 : width - (isBrowser ? document?.documentElement?.clientWidth || 0 : 0);

    const [scrollWidth, setScrollWidth] = useState(initialWidth);
    useEffect(() => {
        setScrollWidth(width - document?.documentElement?.clientWidth || 0);
    });

    return (
        <div
            id="scroll-width"
            data-test-id="scrollWidth"
            style={{ "--scroll-width": `${scrollWidth}px` } as React.CSSProperties}
        >
            {children}
        </div>
    );
};

export default ScrollWidth;
