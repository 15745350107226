import { default as stripSlash } from "@jmc/utils/utils/strip-slash";
import { ApplicationState } from "@redux/modules";
import { AuthData, ValidAuth, ValidAuthResults } from "@redux/modules/authValid";
import { useSelector } from "react-redux";

import { AuthProviders } from "../../api/authProviders";

/**
 * Class to handle all information about a logged in user.
 */
export class UserInfo {
    authData;

    constructor(authData: AuthData) {
        this.authData = authData;
    }

    getUserId(): string {
        let userId = null;
        this.authData?.data?.results.length &&
            this.authData?.data?.results?.map((res: ValidAuth) => {
                if (res?.provider === AuthProviders.patient && res?.userId) userId = res?.userId;
            });

        return userId;
    }

    getAuthData(): ValidAuthResults {
        return this.authData?.data;
    }

    isAuthenticated(): boolean {
        return this.authData?.data?.results?.some((v) => v.provider === AuthProviders.patient) || false;
    }

    isAuthInProgress(): boolean {
        return this.authData.loading;
    }

    isAuthFailed(): boolean {
        return !!this.authData.error;
    }

    isAuthPending(): boolean {
        return !this.authData.loading && !this.authData.data && !this.authData.error;
    }

    /**
     * Return the provider information.
     * You can also call this function with "PROVIDED_ACCESS_CODE" as provider to get
     * access code data.
     * @param provider string
     * @returns JSON
     */
    getProvider(provider = AuthProviders.patient): unknown {
        return this.authData?.data?.results.find((v) => v.provider === provider);
    }

    /**
     * Return TRUE the provider is present.
     *
     * @param provider string
     * @returns TRUE if provider exists in authData
     */
    hasProvider(provider = AuthProviders.patient): boolean {
        return !!this.getProvider(provider);
    }

    /**
     * Return the user email address.
     *
     * @returns user email address.
     */
    getEmail(): string {
        let email = null;
        this.authData?.data?.results.length &&
            this.authData?.data?.results?.map((res: ValidAuth) => {
                if (res?.provider === AuthProviders.patient && res?.email) email = res?.email;
            });

        return email;
    }

    /**
     * Check if the user has access to the provided URL with an Access Code.
     *
     * @param redirectUrl URL to check
     * @returns TRUE if there is a valid acces code.
     */
    isValidAccessCodeForPage(redirectUrl: string): boolean {
        let isValid = false;
        const accessCodeData = this.authData?.data?.results?.filter(
            (element) => element?.provider === AuthProviders.accessCode,
        );
        const decodedUrl = decodeURI(redirectUrl);

        accessCodeData?.forEach((acData) => {
            acData?.urls?.forEach((url) => {
                // Check URLs with and without leading slash
                if (url === decodedUrl || url === stripSlash.stripLeading(decodedUrl)) {
                    isValid = true;
                }
            });
        });

        return isValid;
    }
}

/**
 * Use this hook to get a class with all user information methods.
 * The user data is fetched from the redux store.
 *
 * @returns UserInfo
 */
export const useUserInfo = (): UserInfo => {
    const authData = useSelector((state: ApplicationState) => state.authValid);

    return new UserInfo(authData);
};

export default useUserInfo;
