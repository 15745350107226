import React, { createContext, useContext } from "react";

interface PropTypes {
    hashKey: string;
    children: JSX.Element;
}

export const queryStringStorageContext = createContext(null);

export const QueryStringStorageProvider = ({ children, hashKey }: PropTypes): JSX.Element => {
    return <queryStringStorageContext.Provider value={hashKey}>{children}</queryStringStorageContext.Provider>;
};

export const useQSSKey = (): string => {
    const hashKey = useContext(queryStringStorageContext);
    return hashKey;
};

export default { QueryStringStorageProvider, queryStringStorageContext, useQSSKey };
