import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { HEADER_WITH_TOPBAR, HEADER_COLLAPSED } from "../../components/Header/Header";

export interface HeaderProperties {
    isCollapsed: boolean;
    hasTopBar: boolean;
    height: number;
}

/**
 * Check the header to see if:
 *  - It's collapsed
 *  - Has a topbar
 *  - Its height
 */
export const useHeaderProperties = (): HeaderProperties => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [hasTopBar, setHasTopBar] = useState(false);
    const [height, setHeight] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const topBar = document?.getElementById("main-header")?.classList?.contains(HEADER_WITH_TOPBAR);
        setHasTopBar(topBar);
    }, [location]);

    useEffect(() => {
        const config = { attributes: true, childList: false, subtree: false };
        const targetElement = document?.getElementById("main-header");
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const { target } = mutation;
                if (mutation.attributeName === "class") {
                    const collapsed = (target as HTMLElement)?.classList?.contains(HEADER_COLLAPSED);
                    setIsCollapsed(collapsed);
                }

                if ((target as HTMLElement)?.offsetHeight !== height)
                    setHeight((target as HTMLElement)?.offsetHeight || 0);
            });
        });

        observer.observe(targetElement, config);

        return () => observer.disconnect();
    }, []);

    return { isCollapsed: isCollapsed, hasTopBar: hasTopBar, height: height };
};
