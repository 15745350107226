import { HeaderProperties } from "@jmc/core/src/hooks/useHeaderProperties/useHeaderProperties";
import { anchorScroll } from "@jmc/core/src/utils/anchor-scroll";

export const getStyle = (variable: string): number => {
    return parseInt(getComputedStyle(document.documentElement)?.getPropertyValue(variable), 10);
};

export const jwmAnchorScroll = (anchor: string, headerProps: HeaderProperties, wait = false): void => {
    // Calculate top navigation offset for collapsed
    const mainHeight = getStyle("--header-main-height");
    const topBarHeight = getStyle("--header-top-bar-height");

    let scrollOffset = mainHeight;
    if (headerProps?.hasTopBar) scrollOffset += topBarHeight;

    // Calculate offset if TOC bar is present
    const tocHeight = document?.getElementById("toc-bar")?.offsetHeight;
    const offset = tocHeight > 12 ? tocHeight - 12 : 0;

    if (wait) {
        setTimeout(() => {
            anchorScroll(anchor, offset, true, headerProps?.isCollapsed ? scrollOffset : 0);
        }, 750);
    } else {
        anchorScroll(anchor, offset, true, headerProps?.isCollapsed ? scrollOffset : 0);
    }
};

export default jwmAnchorScroll;
